import React from 'react';

/**
 * Components
 */

import { Select } from '../Select';

/**
 * Styles
 */
import {
  GroupContainer,
  FormInputContainer,
  FormInputLabel,
} from './Form-Input.styles';

const FormInput = ({ formInputType, handleChange, label, ...props }) => {
  const renderFormInput = () => {
    if (formInputType === 'select') {
      return <Select {...props} />;
    }
    return (
      <GroupContainer>
        <FormInputContainer onChange={handleChange} {...props} />
        {label ? (
          <FormInputLabel className={props.value.length ? 'shrink' : ''}>
            {label}
          </FormInputLabel>
        ) : null}
      </GroupContainer>
    );
  };
  return renderFormInput();
};

export default FormInput;
