import PatientActionTypes from './patient.types';
import { updateViewingPatientData, updatePatientList } from './patient.utils';

const INITIAL_STATE = {
  patientList: null,
  error: null,
  loading: false,
  viewingPatientData: null,
  viewingPatientLogs: null,
};
/*** 
Listens for Actions
***/
const patientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PatientActionTypes.EDIT_PATIENT_START:
    case PatientActionTypes.FETCH_PATIENTS_START:
    case PatientActionTypes.FETCH_PATIENT_START:
    case PatientActionTypes.NEW_PATIENT_START:
      return {
        ...state,
        loading: true,
      };
    case PatientActionTypes.FETCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patientList: action.payload,
        error: null,
        loading: false,
      };
    case PatientActionTypes.FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        viewingPatientData: action.payload,
        error: null,
        loading: true,
      };
    case PatientActionTypes.FETCH_PATIENTLOG_SUCCESS:
      return {
        ...state,
        viewingPatientLogs: action.payload,
        error: null,
        loading: false,
      };
    case PatientActionTypes.EDIT_PATIENT_SUCCESS:
      return {
        ...state,
        patientList: updatePatientList(state.patientList, action.payload),
        viewingPatientData: updateViewingPatientData(
          state.viewingPatientData,
          action.payload
        ),
        error: null,
        loading: false,
      };
    case PatientActionTypes.NEW_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PatientActionTypes.EDIT_PATIENT_FAILURE:
    case PatientActionTypes.FETCH_PATIENT_FAILURE:
    case PatientActionTypes.FETCH_PATIENTS_FAILURE:
    case PatientActionTypes.NEW_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default patientReducer;
