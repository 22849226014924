import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

/**
 * Actions
 */

import { signInSuccess } from './redux/user/user.actions';

/**
 * Components
 */

import { Alert } from './components/Alert';
import { Header } from './components/Header';
import { HeaderOnly } from './components/HeaderOnly';
/**
 * Pages
 */
import { CalendarPage } from './pages/CalendarPage';
import { DashboardPage } from './pages/DashboardPage';
import { DevicesPage } from './pages/DevicesPage';
import { EditPatientProfilePage } from './pages/EditPatientProfilePage';
import { ErrorBoundary } from './components/ErrorBoundary';
import { FileStoragePage } from './pages/FileStoragePage';
import { HomePage } from './pages/HomePage';
import { MyAccountPage } from './pages/MyAccountPage';
import { MobileNewPasswordSuccess } from './pages/MobileNewPasswordSuccess';
import { NewPatientPage } from './pages/NewPatientPage';
import { NewMobilePasswordPage } from './pages/NewMobilePSWDPage';
import { NewWebPasswordPage } from './pages/NewWebPSWDPage';
import { PatientProfilePage } from './pages/PatientProfilePage';
import { PatientProfilesPage } from './pages/PatientProfilesPage';
import { ResetWebPasswordPage } from './pages/ResetWebPasswordPage';
import { SignInPage } from './pages/SignInPage';
import { SignUpPage } from './pages/SignUpPage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';

/**
 * Styles
 */

import { GlobalStyle } from './global.styles';
import Theme from './theme.styles';

const SignedInRoutes = () => {
  return (
    <>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/calendar" component={CalendarPage} />
      <Route exact path="/dashboard" component={DashboardPage} />
      <Route exact path="/devices" component={DevicesPage} />
      <Route exact path="/filestorage" component={FileStoragePage} />
      <Route exact path="/patientprofiles" component={PatientProfilesPage} />
      <Route path="/patient/:patientid" component={PatientProfilePage} />
      <Route path="/newpatient" component={NewPatientPage} />
      <Route path="/editpatient" component={EditPatientProfilePage} />
      <Route path="/webuser/myaccount" component={MyAccountPage} />
      <Route path="/webuser/newuser" component={SignUpPage} />
    </>
  );
};

const SignedOutRoutes = () => {
  return (
    <>
      <Route path="/resetpassword/:token" component={NewMobilePasswordPage} />
      <Route
        path="/resetpasswordsuccess"
        component={MobileNewPasswordSuccess}
      />
      <Route path="/tcs" component={TermsAndConditionsPage} />
      <Route
        exact
        path="/webuser/resetpassword"
        component={ResetWebPasswordPage}
      />
      <Route
        exact
        path="/webuser/resetpassword/:token"
        component={NewWebPasswordPage}
      />
      <Route exact path="/webuser/signin" component={SignInPage} />
    </>
  );
};

function App({ pushStoredUser, currentUser }) {
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      pushStoredUser(user);
      history.push('/dashboard');
    } else {
      if (
        !history.location.pathname.startsWith('/resetpassword') &&
        !history.location.pathname.startsWith('/tcs') &&
        !history.location.pathname.startsWith('/webuser/resetpassword')
      ) {
        history.push('/webuser/signin');
      }
    }
  }, []);

  return (
    <>
      <Theme>
        <GlobalStyle currentUser={currentUser} />
      </Theme>
      {currentUser ? <Header /> : <HeaderOnly />}
      <Alert autoDelete />
      <Switch>
        <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
          <SignedInRoutes />
          <SignedOutRoutes />
        </Sentry.ErrorBoundary>
      </Switch>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  pushStoredUser: (user) => dispatch(signInSuccess(user)),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
