import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

/**
 * Actions
 */

import { signOutStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { Avatar } from '../Avatar';
import { Action } from '../Action';
import { DropdownMenu } from '../DropdownMenu';
import { Nav } from '../Nav';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as InboxIcon } from '../../assets/icons/inbox.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification.svg';

/**
 * Hooks
 */

import { useWindowSize } from '../../hooks/useWindowSize';

/**
 * Styles
 */

import {
  HeaderContainer,
  LogoWrapper,
  LogoContainer,
  OptionsContainer,
  OptionLink,
  IconWrapper,
  IconContainer,
} from './Header.styles.js';
import Theme from '../../theme.styles';

/**
 * Header Component
 */

const Header = ({ currentUser, signOutStart }) => {
  const windowSize = useWindowSize();
  const { width } = windowSize;
  const mobile = width < 800;

  const [toggled, setToggle] = useState(false);
  const [toggleBurger, setToggleBurger] = useState(false);

  return (
    <Theme>
      <Nav toggleBurger={toggleBurger} />
      <header>
        <HeaderContainer>
          <LogoWrapper>
            {mobile && (
              <Action
                icn={{
                  name: 'hamburgermenu',
                  size: 'medium',
                  variant: 'tertiary',
                }}
                handleClick={() => setToggleBurger(!toggleBurger)}
              />
            )}
            <LogoContainer to="/dashboard">
              <Logo className="logo" />
            </LogoContainer>
          </LogoWrapper>
          {currentUser && (
            <OptionsContainer>
              <IconWrapper>
                <IconContainer>
                  <InboxIcon />
                </IconContainer>
              </IconWrapper>
              <IconWrapper>
                <IconContainer>
                  <NotificationIcon />
                </IconContainer>
              </IconWrapper>
              <div>
                <Avatar
                  onClick={() => setToggle(!toggled)}
                  handleMouseEnter={() => setToggle(!toggled)}
                  small
                >
                  {/* remove after development */}
                  {currentUser && currentUser.name}
                </Avatar>
              </div>
            </OptionsContainer>
          )}
          {!toggled ? null : (
            <DropdownMenu
              handleMouseLeave={() => setToggle(!toggled)}
              handleSignOut={signOutStart}
            />
          )}
        </HeaderContainer>
      </header>
    </Theme>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
