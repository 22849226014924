import React from 'react';

/**
 * Components
 */

import { NewMobilePassword } from '../../components/NewMobilePassword';

/**
 * Styles
 */

import { SignInContainer } from './NewMobilePassword.page.style.js';

const NewWebPasswordPage = () => {
  return (
    <main>
      <SignInContainer>
        <NewMobilePassword />
      </SignInContainer>
    </main>
  );
};

export default NewWebPasswordPage;
