import { take, call, all } from 'redux-saga/effects';

import { watchFetchDataSaga } from './dummyApiUser/fetchData.saga';
import {
  onEmailSigninStart,
  onResetPasswordStart,
  onSignOutStart,
  onSignUpStart,
  onNewMobilePasswordStart,
  onNewWebPasswordStart,
} from '../redux/user/user.sagas';

import {
  onEditPatientStart,
  onFetchPatientsStart,
  onFetchPatientStart,
  onNewPatientStart,
} from './patient/patient.sagas';

export default function* rootSaga() {
  yield all([
    watchFetchDataSaga(),

    onEmailSigninStart(),
    onEditPatientStart(),
    onFetchPatientsStart(),
    onFetchPatientStart(),
    onNewPatientStart(),
    onResetPasswordStart(),
    onSignOutStart(),
    onSignUpStart(),
    onNewMobilePasswordStart(),
    // onNewWebPasswordStart(),
  ]);
}
