import React from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

/**
 * Actions
 */

import { editPatientStart } from '../../redux/patient/patient.actions';

/**
 * Components
 */

import { FormInput } from '../Form-Input';
import { Button } from '../Button';
import { Text } from '../Text';

/**
 * Styles
 */

import {
  EditPatientContainer,
  EditPatientHeader,
  EditPatientTitle,
  DateContainer,
  FormContainer,
} from './EditPatientProfile.styles';

/**
 * NewPatient Component
 */

const EditPatientProfile = ({
  viewingPatientData,
  loading,
  editPatientStart,
}) => {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      name: viewingPatientData ? viewingPatientData.name : '',
      breed: viewingPatientData ? viewingPatientData.breed : '',
      willowId: viewingPatientData ? viewingPatientData.willowId : '',
      dob: viewingPatientData ? viewingPatientData.dob : '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Name should be longer than 2 characters')
        .required(),
      breed: Yup.string()
        .min(2, 'Breed should be longer than 2 characters')
        .required(),
      willowId: Yup.string()
        .min(2, 'ID should be longer than 2 characters')
        .required(),
      dob: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const token = localStorage.getItem('jwt');
      editPatientStart({ token, values, patientid: viewingPatientData._id });
    },
  });

  return (
    <EditPatientContainer>
      <EditPatientHeader>
        <EditPatientTitle>Edit Patient</EditPatientTitle>
      </EditPatientHeader>
      <form className="new-patient-form" onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="name"
            type="name"
            id="name"
            label="Name"
            value={values.name}
            handleChange={handleChange}
            required
          />
          {touched.name && errors.name && <div>{errors.name}</div>}
          <FormInput
            name="breed"
            type="text"
            id="breed"
            label="Breed"
            value={values.breed}
            handleChange={handleChange}
            required
          />
          {touched.breed && errors.breed && <div>{errors.breed}</div>}
          <FormInput
            name="willowId"
            type="text"
            id="willowId"
            label="Willow ID"
            value={values.willowId}
            handleChange={handleChange}
            required
          />
          {touched.willowId && errors.willowId && <div>{errors.willowId}</div>}

          <DateContainer>
            <Text>Birth Date</Text>
            <input
              className="dob"
              name="dob"
              id="dob"
              type="date"
              placeholder="YYYY-MM-DD"
              data-date-split-input="true"
              onChange={handleChange}
            />
          </DateContainer>
          {touched.dob && errors.dob && <div>{errors.dob}</div>}
        </FormContainer>
        <Button loading={loading} type="submit" margin>
          Update
        </Button>
      </form>
    </EditPatientContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    editPatientStart: (editPatientData) => {
      dispatch(editPatientStart(editPatientData));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.patientReducer.loading,
    viewingPatientData: state.patientReducer.viewingPatientData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientProfile);
