import styled, { css } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

const subColor = 'grey';

export const SelectContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 25px 0;
`;
export const SelectInputContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${subColor};
`;

export const SelectInput = styled.input`
  border: none;
  width: 100%;
`;

export const SelectOptionsContainer = styled.ul`
  background-color: ${(props) => props.theme.colours.uiTwo};
  padding: 0 ${spacing.xxs};
  /* *************** */
  display: block;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: ${zIndex.overlay};
  margin-top: 0;
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 20%;
  right: 10px;
`;

export const SelectOptionButton = styled.button`
  border: none;
  background-color: Transparent;
  background-repeat: no-repeat;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: lightgrey;
    color: white;
  }
`;
