import styled from 'styled-components';

export const SignInContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

export const SignInTitle = styled.h2`
  margin: 10px 0;
`;

export const ButtonsBarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const SignInHeader = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
`;
