import * as React from 'react';
import { useState } from 'react';
import Theme from '../../theme.styles';

/**
 * Styles
 */
import {
  ImageContainer,
  ImagePicture,
  ImageLoader,
  ImageImage,
  ImageCapture,
} from './Image.styles';

/**
 * An Image component with source set
 *
 * @todo add proper loader/spinner
 */
const Image = ({
  isRounded,
  isRound,
  aspectRatio = 1 / 1,
  src,
  srcSet = [],
  alt = 'Image',
  caption,
  onLoad,
}) => {
  const [loading, setLoading] = useState(true);

  /**
   * Set loading
   */
  const handleLoad = () => {
    setLoading(false);
    if (!onLoad) return;
    onLoad();
  };

  return src ? (
    <Theme>
      <ImageContainer className="img" isRounded={isRounded} isRound={isRound}>
        <ImagePicture className="img__picture" aspectRatio={aspectRatio}>
          {/* {loading && (
            <ImageLoader className="img__loader">Loading...</ImageLoader>
          )} */}
          {srcSet.map((img, i) => (
            <source key={`src-${i}`} media={img.media} srcSet={img.srcSet} />
          ))}
          <ImageImage
            className="img__img"
            src={src}
            alt={alt}
            // onLoad={handleLoad}
          />
        </ImagePicture>
        {caption && (
          <ImageCapture className="img__caption">{caption}</ImageCapture>
        )}
      </ImageContainer>
    </Theme>
  ) : null;
};

export default Image;
