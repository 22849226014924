import React from 'react';

/**
 * Components
 */

import { Nav } from '../../components/Nav';
import { SignUp } from '../../components/Sign-Up';

/**
 * Styles
 */

import { SignUpContainer } from './SignUp.page.style.js';

const SignUpPage = () => {
  return (
    <>
      <main>
        <SignUpContainer>
          <SignUp />
        </SignUpContainer>
      </main>
    </>
  );
};

export default SignUpPage;
