import styled, { css } from 'styled-components';

/// ------------------
/// Image selectors
/// ------------------
export const ImageContainer = styled.figure`
  background-color: transparent;
  box-sizing: border-box;
  margin: 0;
  position: relative;

  ${(props) =>
    props.isRound &&
    `
    border-radius: ${(props) => props.theme.corners.round};
`}
  ${(props) =>
    props.isRounded &&
    `
    border-radius: ${(props) => props.theme.corners.base};
`};
`;

export const ImagePicture = styled.picture`
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => (1 / props.aspectRatio) * 100}%;
`;

/// -------------------
/// Image loader
/// ------------------

export const ImageLoader = styled.span`
  ${(props) => props.theme.utilities.center};
`;

/// ------------------
/// Image item
/// ------------------
export const ImageImage = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

/// ------------------
/// Image item
/// ------------------
export const ImageCapture = styled.figcaption`
  background-color: colours.$ui-one;
  margin: 0;
  padding: 0;
  width: 100%;
`;
