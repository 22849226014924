import * as React from 'react';

/**
 * Styles & Assets
 */
import { StyledText } from './Text.style';

/**
 * A Text component
 */
const Text = ({
  className,
  id,
  variant,
  tag = 'p',
  textAlignment,
  bold,
  inverse,
  upperCase,
  children,
}) => {
  return children ? (
    // the trick here is the "as={...}" to create dynamic tag name
    <StyledText
      as={tag}
      id={id}
      className="Text"
      upperCase={upperCase}
      textAlignment={textAlignment}
      variant={variant}
      bold={bold}
    >
      {children}
    </StyledText>
  ) : null;
};

export default Text;
