import * as React from 'react';
import { Fragment, useMemo, useState, useEffect } from 'react';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import {
  IconContainer,
  DrawerContentsContainer,
  DrawerContentsContainerMobile,
  DrawerContainerOuter,
  DrawerContainerInner,
} from './Drawer.styles.js';

/**
 * Components
 */

import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';

/**
 * Hooks
 */

import { useWindowSize } from '../../hooks/useWindowSize';
import { DrawerContext } from '.';

/**
 * Positions
 */
const positions = {
  Left: { x: 'Start', y: 'Start' },
  Right: { x: 'End', y: 'Start' },
  Top: { x: 'Start', y: 'Start' },
  Bottom: { x: 'Start', y: 'End' },
};

/**
 * States
 */
const states = {
  Closed: 'animate',
  Opening: 'animate animate--fade-in-{{position}}',
  Open: 'animate drawer--open',
  Closing: 'animate animate--fade-in-{{position}} animate--reverse',
};

const DrawerBox = ({ isOpen, children }) => (
  <DrawerContentsContainer
    className={`Drawer__Container ${isOpen ? 'Drawer__Container--isOpen' : ''}`}
    isOpen={isOpen}
  >
    <div className="DrawerContents__Container">{children}</div>
  </DrawerContentsContainer>
);

const DrawerBoxMobile = ({ isOpen, children }) => (
  <DrawerContentsContainerMobile
    className={`Drawer__Container ${isOpen ? 'Drawer__Container--isOpen' : ''}`}
    isOpen={isOpen}
  >
    <div className="DrawerContents__Container">{children}</div>
  </DrawerContentsContainerMobile>
);

/**
 * A drawer container that slides out and overlays the main body
 */
const Drawer = ({ position = 'Left', children, handleClose, toggleBurger }) => {
  const windowSize = useWindowSize();
  const { width } = windowSize;
  const mobile = width < 800;

  const [isOpen, setIsOpen] = useState(false);

  const drawerContext = useMemo(
    () => ({
      handleClose,
      position,
    }),
    [handleClose, position]
  );

  useEffect(() => {
    setIsOpen(toggleBurger);
  }, [toggleBurger]);

  return (
    <Theme>
      <DrawerContext.Provider value={drawerContext}>
        <Fragment>
          {!mobile ? (
            <DrawerContainerOuter
              className="Drawer__Container--outer"
              onMouseLeave={() => setIsOpen(false)}
            >
              <DrawerContainerInner className="Drawer__Container--inner">
                <IconContainer>
                  {isOpen ? (
                    <ChevronLeft onClick={() => setIsOpen(false)} />
                  ) : (
                    <ChevronRight onClick={() => setIsOpen(true)} />
                  )}
                </IconContainer>
                <DrawerBox isOpen={isOpen}>{children}</DrawerBox>
              </DrawerContainerInner>
            </DrawerContainerOuter>
          ) : (
            <DrawerBoxMobile isOpen={isOpen}>{children}</DrawerBoxMobile>
          )}
        </Fragment>
      </DrawerContext.Provider>
    </Theme>
  );
};

export default Drawer;
