import { useState } from 'react';

/**
 * Manage items in an array
 *
 * @param {Object} options
 * An options config
 */
const useManageArray = () => {
  const [array, setArray] = useState(null);

  /**
   * Add an item
   */
  const addItem = (value) => {
    setArray((prev) => [...(prev || []).filter((x) => x !== value), value]);
  };

  /**
   * Delete an item
   */
  const deleteItem = (value) => {
    setArray((prev) => [...(prev || []).filter((x) => x !== value)]);
  };

  /**
   * Reset all items
   */
  const resetItems = (value) => {
    setArray(value);
  };

  return { array, addItem, deleteItem, resetItems };
};

export default useManageArray;
