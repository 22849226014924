import styled, { css } from 'styled-components';

const columns = '12';

export const GridItemContainer = styled.div`
  box-sizing: border-box;
  grid-column: span ${(props) => props.columnSize};
  margin: ${(props) => (props.centerAlignContent ? 'auto' : '0')};
`;

export const GridContainer = styled.div`
  box-sizing: border-box;
  /* IE11 fallback */
  display: flex;
  display: grid;
  /* IE11 fallback */
  flex-wrap: wrap;
  grid-template-columns: repeat(${columns}, minmax(0, 1fr));
  width: 100%;
`;
