import React from 'react';
import { useState, useEffect, useRef } from 'react';

/**
 * Components
 */

import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';

/**
 * Hooks
 */

import { useManageArray } from '../../hooks/useManageArray';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import {
  SelectContainer,
  SelectInputContainer,
  SelectInput,
  IconContainer,
  SelectOptionButton,
  SelectOptionsContainer,
} from './Select.styles';

/**
 * Select component - Determine which select type to render
 */
const Select = ({ value = null, onChange, label, ...props }) => {
  const inputEl = useRef(null);
  const [focused, setFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [shownValue, setShownValue] = useState('');
  const { array: values, addItem, deleteItem, resetItems } = useManageArray();

  /**
   * Filter the options if a search value has been entered
   */
  const filtered =
    props.searchable && !props.autocomplete
      ? props.options.filter((x) =>
          x.label?.toLowerCase().includes(searchValue?.toLowerCase() || '')
        )
      : props.options;

  /**
   * When we focus, open the options
   */
  const handleFocus = () => {
    setFocused(true);
  };

  /**
   * On blur
   */
  const handleBlur = (e) => {
    const isOutside =
      !inputEl.current.contains(e.currentTarget) ||
      !inputEl.current.contains(e.relatedTarget);
    if (isOutside) {
      setFocused(false);
      setSearchValue(null);
    }
  };

  const handleClick = (optionValue) => {
    if (props.multi) {
      const index = values ? values.indexOf(optionValue) : -1;
      if (index === -1) {
        addItem(optionValue);
      } else {
        deleteItem(optionValue);
      }
    } else {
      resetItems([optionValue]);
      setFocused(false);
    }
  };

  /**
   * Component mount
   */
  useEffect(() => {
    if (typeof value === 'string') {
      onChange(value);
    } else if (props.multi && Array.isArray(value)) {
      value.forEach((x) => handleClick(x));
    }
  }, []);

  /**
   * Send the value to the parent onChange fn
   */
  useEffect(() => {
    if (values === null) return;
    if (props.multi) {
      onChange(values.length === 0 ? null : values);
    } else {
      onChange(values[0]);
      setShownValue(values[0]);
    }
  }, [values]);

  console.log(values);

  return (
    <Theme>
      <SelectContainer ref={inputEl} onFocus={handleFocus} onBlur={handleBlur}>
        <SelectInputContainer>
          <SelectInput
            id={props.name}
            name={props.name}
            value={shownValue}
            placeholder={props.placeholder && props.placeholder}
            disabled={props.disabled}
            autoComplete="off"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <IconContainer onClick={handleFocus}>
            {!focused && props.autocomplete ? null : focused ? (
              <ChevronUp />
            ) : (
              <ChevronDown />
            )}
          </IconContainer>
        </SelectInputContainer>
        <SelectOptionsContainer>
          {focused && props.optional && (
            <li className="select__option">
              <SelectOptionButton
                className="select__btn"
                onClick={() => handleClick('')}
              >
                {props.placeholder ? props.placeholder : '-- Select --'}
              </SelectOptionButton>
            </li>
          )}
          {focused &&
            filtered.map((x) => (
              <li key={x.value} className="select__option">
                <SelectOptionButton
                  tabIndex={0}
                  title={x.label}
                  disabled={x.disabled}
                  onClick={() => handleClick(x.value)}
                  variant="transparent"
                >
                  {props.multi && (
                    // <Checkbox
                    //   className="select__checkbox"
                    //   id={x.label}
                    //   value={values?.includes(x.value)}
                    //   onChange={() => {}}
                    // />
                    <input type="checkbox" />
                  )}
                  {filtered.find((y) => y.value === x.value)?.label}
                </SelectOptionButton>
              </li>
            ))}
        </SelectOptionsContainer>
      </SelectContainer>
    </Theme>
  );
};

export default Select;
