import { takeLatest, put, all, call } from 'redux-saga/effects';

import axios from 'axios';

import { push } from 'connected-react-router';

import UserActionTypes from './user.types';

import {
  newMobilePasswordFailure,
  newMobilePasswordSuccess,
  newWebPasswordFailure,
  newWebPasswordSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
  signInFailure,
  signInSuccess,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
} from './user.actions';
import { addAlertToList, remAllAlertList } from '../alert/alert.actions';

/**
 * GENERATOR FUNCTIONS
 **/

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const url = 'https://pet2vet-server.herokuapp.com/webuser/signin';
    const response = yield call(() =>
      axios.post(url, {
        email,
        password,
      })
    );
    localStorage.setItem('jwt', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    yield put(
      addAlertToList({
        description: 'Successfully Signed In',
        variant: 'success',
      })
    );
    yield put(signInSuccess(response.data.user));
    yield put(push('/dashboard'));
  } catch ({ response, error }) {
    console.log(error);
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  try {
    localStorage.clear();
    yield put(signOutSuccess());
    yield put(push('/webuser/signin'));
    yield put(remAllAlertList());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password, name, telephone } }) {
  console.log(email);
  try {
    const url = 'https://pet2vet-server.herokuapp.com/webuser/signup';
    const response = yield call(() =>
      axios.post(url, {
        email,
        password,
        name,
        telephone,
        tcs: true,
      })
    );
    yield put(signUpSuccess());
    yield put(
      addAlertToList({
        description: response.data.message,
        variant: 'success',
      })
    );
    yield put(push('/dashboard'));
  } catch ({ response, error }) {
    console.log(error);
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(signInFailure(error));
  }
}

export function* resetPassword({ payload: { email } }) {
  try {
    const url = 'https://pet2vet-server.herokuapp.com/webuser/resetpassword';
    const response = yield call(() =>
      axios.post(url, {
        email,
      })
    );
    yield put(resetPasswordSuccess());
    yield put(
      addAlertToList({
        description: response.data.message,
        variant: 'success',
      })
    );
    signOut();
  } catch ({ response, error }) {
    console.log(error);
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(resetPasswordFailure(error));
  }
}

export function* newWebPassword({ payload: { password, token } }) {
  try {
    const url = 'https://pet2vet-server.herokuapp.com/webuser/newpassword';
    const response = yield call(() =>
      axios.post(url, {
        password,
        token,
      })
    );
    yield put(newWebPasswordSuccess());
    yield put(
      addAlertToList({
        description: response.data.message,
        variant: 'success',
      })
    );
    yield put(push('/webuser/signin'));
  } catch ({ response, error }) {
    console.log(error);
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(newWebPasswordFailure(error));
  }
}

export function* newMobilePassword({ payload: { password, token } }) {
  try {
    const url = 'https://pet2vet-server.herokuapp.com/newpassword';
    const response = yield call(() =>
      axios.post(url, {
        password,
        token,
      })
    );
    yield put(newMobilePasswordSuccess());
    yield put(
      addAlertToList({
        description: response.data.message,
        variant: 'success',
      })
    );
    yield put(push('/resetpasswordsuccess'));
  } catch ({ response, error }) {
    console.log(error);
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(newMobilePasswordFailure(error));
  }
}

/**
 * Listeners
 **/

//This is the listner for Email Sign In
export function* onEmailSigninStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onNewMobilePasswordStart() {
  yield takeLatest(
    UserActionTypes.NEW_MOBILE_PASSWORD_START,
    newMobilePassword
  );
}

export function* onNewWebPasswordStart() {
  yield takeLatest(UserActionTypes.NEW_WEB_PASSWORD_START, newWebPassword);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS);
}

export function* onResetPasswordStart() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_START, resetPassword);
}

/**
 * Main Listening SAGA
 **/
export function* userSagas() {
  yield all([
    call(onEmailSigninStart),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onResetPasswordStart),
    call(onNewMobilePasswordStart),
    call(onNewWebPasswordStart),
  ]);
}

//PUT puts things back into our regular redux flow
