import { fetchDummyDataTypes } from './fetchData.types';

const initialState = {
  loading: false,
  user: 'john',
  error: {},
};

const fetchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchDummyDataTypes.SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case fetchDummyDataTypes.SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: {},
      };
    case fetchDummyDataTypes.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    default:
      return {
        state,
      };
  }
};

export default fetchDataReducer;
