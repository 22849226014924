import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Theme from '../../theme.styles';
import { useHistory } from 'react-router-dom';

/**
 * Components
 */
import { Avatar } from '../Avatar';

/**
 * Styles
 */
import {
  ProfileCardContainer,
  ProfileCardDetails,
  ProfileCardImage,
  ProfileCardLabel,
  ProfileCardWrapper,
  RowDetails,
  LHSRow,
  RHSRow,
} from './ProfileCard.styles';

/**
 * An Avatar component
 */
const ProfileCard = ({ patientInfo }) => {
  const history = useHistory();

  const formatDate = (string) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  };

  const renderProfile = () => {
    const { breed, dob, name, willowId, _id } = patientInfo;
    return (
      <Theme>
        <ProfileCardWrapper className="proprofile-wrap">
          <ProfileCardContainer className="upp-profile-wrapper">
            <div>
              {}
              {/* <ProfileCardImage
              className="profile-pic"
              src={require('../../assets/images/account-profile-img.png')}
            /> */}
              <Avatar onClick={() => history.push('/editpatient')}>
                {name}
              </Avatar>
              <ProfileCardLabel className="profile-pic-label">
                <div>Edit Profile</div>
              </ProfileCardLabel>
            </div>
            <div>
              <ProfileCardDetails className="profile-stats">
                <RowDetails className="row-details">
                  <LHSRow>Name:</LHSRow>
                  <RHSRow>{name}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>Breed:</LHSRow>
                  <RHSRow>{breed}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>DOB:</LHSRow>
                  <RHSRow>{formatDate(dob)}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>WillowID:</LHSRow>
                  <RHSRow>{willowId}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>DatabaseID:</LHSRow>
                  <RHSRow>{_id}</RHSRow>
                </RowDetails>
              </ProfileCardDetails>
            </div>
          </ProfileCardContainer>
        </ProfileCardWrapper>
      </Theme>
    );
  };

  return <div>{patientInfo && renderProfile()}</div>;
};

export default ProfileCard;
