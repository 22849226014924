import React from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

/**
 * Actions
 */

import { newPatientStart } from '../../redux/patient/patient.actions';

/**
 * Components
 */

import { FormInput } from '../Form-Input';
import { Button } from '../Button';
import { Text } from '../Text';

/**
 * Styles
 */

import {
  NewPatientContainer,
  NewPatientTitle,
  DateContainer,
} from './NewPatient.styles';

/**
 * NewPatient Component
 */

const NewPatient = ({ loading, newPatientStart }) => {
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      breed: '',
      willowId: '',
      dob: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Name should be longer than 2 characters')
        .required(),
      breed: Yup.string()
        .min(2, 'Breed should be longer than 2 characters')
        .required(),
      willowId: Yup.string()
        .min(2, 'ID should be longer than 2 characters')
        .required(),
      dob: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const token = localStorage.getItem('jwt');
      newPatientStart({ token, values });
    },
  });

  return (
    <NewPatientContainer>
      <NewPatientTitle>Create New Patient</NewPatientTitle>
      <form className="new-patient-form" onSubmit={handleSubmit}>
        <FormInput
          name="name"
          type="name"
          id="name"
          label="Name"
          value={values.name}
          handleChange={handleChange}
          required
        />
        {touched.name && errors.name && <div>Patient name required</div>}
        <FormInput
          name="breed"
          type="text"
          id="breed"
          label="Breed"
          value={values.breed}
          handleChange={handleChange}
          required
        />
        {touched.breed && errors.breed && <div>Breed required</div>}
        <FormInput
          name="willowId"
          type="text"
          id="willowId"
          label="Willow ID"
          value={values.willowId}
          handleChange={handleChange}
          required
        />
        {touched.willowId && errors.willowId && <div>Willow ID required</div>}

        <DateContainer>
          <Text>Birth Date</Text>
          <input
            className="dob"
            name="dob"
            id="dob"
            type="date"
            placeholder="YYYY-MM-DD"
            data-date-split-input="true"
            onChange={handleChange}
          />
        </DateContainer>
        {touched.dob && errors.dob && <div>Date of birth required</div>}
        <Button loading={loading} type="submit">
          Submit
        </Button>
      </form>
    </NewPatientContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    newPatientStart: (newPatientData) => {
      dispatch(newPatientStart(newPatientData));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.patientReducer.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPatient);
