import * as React from 'react';
import Theme from '../../theme.styles';

/**
 * Component
 */

import { ReactComponent as Logo } from '../../assets/logo.svg';

/**
 * Styles
 */
import { LogoWrapper, LogoContainer, SpinnerContainer } from './Spinner.styles';

/**
 * An Image component with source set
 */
const Spinner = () => {
  // useEffect(() => {
  //     const interval = setInterval(() => {
  //       if (autoDelete && alertList.length) {
  //         remAlertToList(alertList[0].description);
  //       }
  //     }, 3000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, [alertList]);

  return (
    <Theme>
      <SpinnerContainer>
        <LogoWrapper>
          <LogoContainer>
            <Logo className="logo" />
          </LogoContainer>
        </LogoWrapper>
      </SpinnerContainer>
    </Theme>
  );
};

export default Spinner;
