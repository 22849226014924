import styled from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

export const DrawerContainerOuter = styled.div`
  position: fixed;
  top: 56px;
  background-color: transparent;
  height: 100vh;
  width: 75px;
  /* width: calc(10vw - 3.5rem); */
  z-index: ${zIndex.top};
`;

export const DrawerContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const IconContainer = styled.div`
  opacity: 0.5;
  fill: ${(props) => props.theme.colours.uiTwo};
  position: absolute;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndex.alert};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    height: 55px;
    width: 55px;
  }
`;

export const DrawerContents__Container = styled.div``;

export const DrawerContentsContainer = styled.div`
  height: 100vh;
  width: 400px;
  top: 56px;
  background-color: ${(props) => props.theme.colours.brandOne};
  transition: all 0.3s ease-in-out;
  transform: translate(-325px);
  position: fixed;
  transform: ${(props) => (props.isOpen ? 'translate(0)' : 'auto')};
  z-index: ${zIndex.modal};
`;

export const DrawerContentsContainerMobile = styled.div`
  height: 100vh;
  width: 100vw;
  top: 56px;
  background-color: ${(props) => props.theme.colours.brandOne};
  transition: all 0.3s ease-in-out;
  transform: translate(-100vw);
  position: fixed;
  transform: ${(props) => (props.isOpen ? 'translate(0)' : 'auto')};
  z-index: ${zIndex.modal};
`;
