/*** 
The actions themselves 
***/

import UserActionTypes from './user.types';

/*** 
SIGN IN
***/

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

/*** 
SIGN OUT
***/
export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});
export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});
export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});
/*** 
SIGN UP
***/

//User Credentials includes, name, email and displayName
export const onSignUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = () => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

/*** 
RESET PASSWORD
***/

//User Credentials includes, name, email and displayName
export const onResetPasswordStart = (email) => ({
  type: UserActionTypes.RESET_PASSWORD_START,
  payload: email,
});

export const resetPasswordSuccess = () => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: UserActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

/*** 
NEW MOBILE PASSWORD
***/

export const onNewMobilePasswordStart = (newPasswordData) => ({
  type: UserActionTypes.NEW_MOBILE_PASSWORD_START,
  payload: newPasswordData,
});

export const newMobilePasswordSuccess = () => ({
  type: UserActionTypes.NEW_MOBILE_PASSWORD_SUCCESS,
});

export const newMobilePasswordFailure = (error) => ({
  type: UserActionTypes.NEW_MOBILE_PASSWORD_FAILURE,
  payload: error,
});

/*** 
NEW WEB PASSWORD
***/

export const onNewWebPasswordStart = (newPasswordData) => ({
  type: UserActionTypes.NEW_WEB_PASSWORD_START,
  payload: newPasswordData,
});

export const newWebPasswordSuccess = () => ({
  type: UserActionTypes.NEW_WEB_PASSWORD_SUCCESS,
});

export const newWebPasswordFailure = (error) => ({
  type: UserActionTypes.NEW_WEB_PASSWORD_FAILURE,
  payload: error,
});
