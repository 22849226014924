/// ----------------------------------------------------------------------

/// Z-Index

/// ----------------------------------------------------------------------
export const zIndex = {
  back: '-1',
  above: '2',
  tooltip: '100',
  pageHeader: '100',
  overlay: '101',
  float: '102',
  modal: '103',
  alert: '104',
  top: '999',
};
