import { fetchDummyDataTypes } from './fetchData.types';

export function fetchData(data) {
  return {
    type: fetchDummyDataTypes.SEND_REQUEST,
    payload: data,
  };
}

export const fetchDataSuccess = (user) => {
  return {
    type: fetchDummyDataTypes.SEND_REQUEST_SUCCESS,
    payload: user,
  };
};

export const fetchDataFailure = (error) => {
  return {
    type: fetchDummyDataTypes.SEND_REQUEST_FAILURE,
    payload: {},
    error: error,
  };
};
