import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Theme from '../../theme.styles';

/**
 * Components
 */
import { Avatar } from '../Avatar';

/**
 * Styles
 */
import {
  ProfileCardContainer,
  ProfileCardDetails,
  ProfileCardImage,
  ProfileCardLabel,
  ProfileCardWrapper,
  RowDetails,
  LHSRow,
  RHSRow,
} from './UserProfileCard.styles';

/**
 * An Avatar component
 */
const UserProfileCard = ({ userInfo }) => {
  const renderProfile = () => {
    const { name, email, telephone } = userInfo;
    return (
      <Theme>
        <ProfileCardWrapper className="proprofile-wrap">
          <ProfileCardContainer className="upp-profile-wrapper">
            <div>
              {}
              {/* <ProfileCardImage
              className="profile-pic"
              src={require('../../assets/images/account-profile-img.png')}
            /> */}
              <Avatar>{name}</Avatar>
            </div>
            <div>
              <ProfileCardDetails className="profile-stats">
                <RowDetails className="row-details">
                  <LHSRow>Name:</LHSRow>
                  <RHSRow>{name}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>Email:</LHSRow>
                  <RHSRow>{email}</RHSRow>
                </RowDetails>
                <RowDetails className="row-details">
                  <LHSRow>Telephone:</LHSRow>
                  <RHSRow>0{telephone}</RHSRow>
                </RowDetails>
              </ProfileCardDetails>
            </div>
          </ProfileCardContainer>
        </ProfileCardWrapper>
      </Theme>
    );
  };

  return <div>{userInfo && renderProfile()}</div>;
};

export default UserProfileCard;
