import styled, { css } from 'styled-components';

export const ProfileCardWrapper = styled.div`
  width: 450px;
  margin: 0px auto;

  @media screen and (max-width: 800px) {
    width: 95%;
  }
`;

export const ProfileCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 18px 0px;
  border-bottom: 1px solid grey;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ProfileCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const ProfileCardImage = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 80px;
  margin: 20px;
`;

export const ProfileCardLabel = styled.div`
  text-align: center;
`;

export const RowDetails = styled.div`
  display: flex;
`;

export const LHSRow = styled.div`
  line-height: 2.5rem;
  font-weight: 600;
  width: 40%;
`;

export const RHSRow = styled.div`
  line-height: 2.5rem;
  width: 60%;
`;
