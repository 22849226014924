import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: rgb(232, 242, 250,0.6);
  margin: 0;
  padding: 0;

  //rules are within argument
  @media screen and (max-width:800px){
    margin: 0;
    padding: 0;
  }
}

main {
  margin:${(props) =>
    props.currentUser
      ? (props) => props.theme.margin.loggedInDesktopMargin
      : (props) => props.theme.margin.loggedOutDesktopMargin};
    
  @media screen and (max-width:800px){
    margin:${(props) =>
      props.currentUser
        ? (props) => props.theme.margin.loggedInMobileMargin
        : (props) => props.theme.margin.loggedOutMobileMargin};
}
}

a {
  text-decoration: none;
  color: black;
}

svg {
  display:block;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: 1px;
  line-height: 3rem;
  list-style: none;
  @media screen and (max-width:800px){

    font-size:14px;
  }
}
`;
