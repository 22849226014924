import styled, { css } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

const getAlertColours = (props) => {
  switch (props.variant) {
    default:
      return '#2396F3';
    case 'error':
      return '#C74243';
    case 'warning':
      return '#DF954A';
    case 'info':
      return '#2396F3';
    case 'success':
      return '#49A154';
  }
};

export const NotificationContainer = styled.div`
  font-size: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  right: 0;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  animation: toast-out-right 0.7s;
  z-index: ${zIndex.alert};
`;

export const RelativeContainer = styled.div`
  background-color: ${getAlertColours};
  border-radius: 5px;
  transition: 0.3s ease;
  position: relative;
  margin: 0 ${spacing.md} ${spacing.md} 0;
  box-shadow: 0 0 10px #999;
  height: 50px;
  min-width: 350px;
  max-width: 600px;
`;
export const AlertOuterContainer = styled.div`
  color: ${(props) => props.theme.colours.uiTwo};
  height: 50px;
  position: absolute;
  left: 50%;
  opacity: 0.85;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  width: 350px;
`;

export const AlertMessage = styled.div`
  display: flex;
  max-width: 100%;
`;

export const Message = styled.div`
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  vertical-align: middle;

  @media screen and (max-width: 800px) {
    width: 15px;
    padding: ${spacing.xxs};
  }
`;

export const IconContainer = styled.div`
  fill: ${(props) => props.theme.colours.uiTwo};
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
`;

export const ActionContainer = styled.div`
  margin: auto;
`;
