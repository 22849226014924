import styled, { keyframes } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

const pulsating = keyframes`
0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
50% {opacity: 1.0;}
100% {-webkit-transform: scale(1, 1); opacity: 0.0;}
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  display: block;
  z-index: ${zIndex.top};
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colours.uiFive};
`;

export const LogoWrapper = styled.div`
  height: 200px;
  width: 200px;
  margin: auto;
  position: relative;
  top: 25%;

  animation: ${pulsating} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;

  @media screen and (max-width: 800px) {
    width: 100px;
    height: 100px;
    top: 35%;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
