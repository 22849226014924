import React from 'react';
import styled, { css } from 'styled-components';
import { ThemeProvider } from 'styled-components';

const theme = {
  margin: {
    loggedOutMobileMargin: '5px 5px 0 5px',
    loggedInMobileMargin: '56px 5px 0 5px',
    loggedOutDesktopMargin: '5px 5px 0 5px',
    loggedInDesktopMargin: '56px 0 0 75px',
  },
  colours: {
    brandOne: '#2E6CA3',
    brandTwo: '#2BAAE2',
    uiOne: 'black',
    uiTwo: 'white',
    uiThree: '#E7DFDE',
    uiFour: '#D0D6E5',
    uiFive: '#E8F2FA',
    uiSix: '#9C898A',
    uiSeven: '#5A81A3',
    uiEight: '#314A69',
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  borders: {
    uiOne: `solid 1px black`,
    uiTwo: `solid 2px #D0D6E5`,
  },
  corners: {
    base: '0',
    round: '999px',
  },
  utilities: {
    /// Make a component fill its parent container
    fill: {
      bottom: '0',
      left: '0',
      position: 'absolute',
      right: '0',
      top: '0',
    },
    /// Center a component within its parent container using absolute positioning
    centerX: {
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
    },

    centerY: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    center: {
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
