import styled from 'styled-components';
import { zIndex } from '../../config/styles/z-index';

export const DropdownMenuContainer = styled.div`
  position: absolute;
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background-color: grey;
  top: 56px;
  right: 40px;
  z-index: ${zIndex.above};
`;
