import * as React from 'react';
import {
  AreaChart,
  linearGradient,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from 'recharts';

/**
 * Hooks
 */

import { useWindowSize } from '../../hooks/useWindowSize';

/**
 * Components
 */
import { Button } from '../Button';
import { Text } from '../Text';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import { ChartWrapper, ChartContainer } from './Chart.styles';

/**
 * A Chart component
 */
const Chart = ({ data, chartConfig }) => {
  const windowSize = useWindowSize();

  const formatDate = (string) => {
    let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(string).toLocaleDateString([], options);
  };

  return (
    <Theme>
      <ChartWrapper>
        <Text textAlignment="center" variant="epilson" bold>
          {chartConfig.chartTitle}
        </Text>
        <ChartContainer windowSize={windowSize}>
          <AreaChart
            width={windowSize.width / 1.5}
            height={windowSize.height / 1.5}
            data={data}
            margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
          >
            <defs>
              {chartConfig.linearGradient.map((item) => (
                <linearGradient
                  key={item.id}
                  id={item.id}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={item.stopColor}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={item.stopColor}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            <XAxis
              dataKey="date"
              tickFormatter={formatDate}
              angle={-45}
              textAnchor="end"
              height={100}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {chartConfig.area.map((item) => (
              <Area
                key={item.dataKey}
                type="monotone"
                dataKey={item.dataKey}
                stroke={item.stroke}
                fillOpacity={1}
                fill={item.fill}
              />
            ))}
            <Legend verticalAlign="top" height={36} />
          </AreaChart>
          <Button margin> 1Week</Button>
          <Button margin> 1Month</Button>
        </ChartContainer>
      </ChartWrapper>
    </Theme>
  );
};

export default Chart;

// changing tick angle https://jsfiddle.net/alidingling/5br7g9d6/
