import React from 'react';

/**
 * Components
 */

import { Text } from '../../components/Text';

/**
 * Styles
 */

import { HeaderContainer } from './DashboardPage.styles.js';

const DashboardPage = () => {
  return (
    <>
      <main>
        <HeaderContainer>
          <Text bold variant="delta">
            Dashboard Indicators
          </Text>
          <Text>Coming Soon........</Text>
        </HeaderContainer>
      </main>
    </>
  );
};

export default DashboardPage;
