import React from 'react';

/**
 * Components
 */

import { NewWebPassword } from '../../components/NewWebPassword';

/**
 * Styles
 */

import { SignInContainer } from './NewWebPassword.page.style.js';

const NewWebPasswordPage = () => {
  return (
    <main>
      <SignInContainer>
        <NewWebPassword />
      </SignInContainer>
    </main>
  );
};

export default NewWebPasswordPage;
