import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Actions
 */

import { emailSignInStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { FormInput } from '../Form-Input';
import { Button } from '../Button';

/**
 * Styles
 */
import {
  FormContainer,
  SignInContainer,
  SignInHeader,
  SignInTitle,
  ButtonsBarContainer,
} from './Sign-In.styles';

const SignIn = ({ emailSignInStart, loading, currentUser }) => {
  const history = useHistory();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Required'),
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      emailSignInStart(values);
    },
  });

  useEffect(() => {
    if (currentUser) {
      return history.push('/dashboard');
    }
  }, [currentUser]);

  return (
    <SignInContainer>
      <SignInHeader>
        <SignInTitle>I already have an account</SignInTitle>
        <span>Sign in with your email and password</span>
      </SignInHeader>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="email"
            type="email"
            id="email"
            label="Email"
            value={values.email}
            handleChange={handleChange}
            required
          />
          {touched.email && errors.email && <div>{errors.email}</div>}
          <FormInput
            name="password"
            type="password"
            id="passowrd"
            label="Password"
            value={values.password}
            handleChange={handleChange}
            required
          />
          {touched.password && errors.password && <div>{errors.password}</div>}
        </FormContainer>
        <ButtonsBarContainer>
          <Button loading={loading} type="submit" margin>
            {' '}
            Sign in{' '}
          </Button>
          <Button
            handleClick={() => history.push('/webuser/resetpassword')}
            margin
          >
            {' '}
            Forgot Password?{' '}
          </Button>
        </ButtonsBarContainer>
      </form>
    </SignInContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    emailSignInStart: (emailAndPassword) => {
      dispatch(emailSignInStart(emailAndPassword));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
    currentUser: state.userReducer.selectCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
