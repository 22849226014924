import React from 'react';

/**
 * Components
 */

import { NewPatient } from '../../components/NewPatient';
import { Nav } from '../../components/Nav';

/**
 * Styles
 */

import { SignUpContainer } from './NewPatient.page.style.js';

const NewPatientPage = () => {
  return (
    <>
      <main>
        <SignUpContainer>
          <NewPatient />
        </SignUpContainer>
      </main>
    </>
  );
};

export default NewPatientPage;
