import { combineReducers } from 'redux';
import fetchDataReducer from './dummyApiUser/fetchData.reducer.js';
import { connectRouter } from 'connected-react-router';

import alertReducer from './alert/alert.reducer';
import patientReducer from './patient/patient.reducer';
import userReducer from './user/user.reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    fetchDataReducer,
    alertReducer,
    patientReducer,
    userReducer,
  });

export default rootReducer;
