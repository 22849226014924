import { createBrowserHistory } from 'history';

import { createStore, applyMiddleware, compose } from 'redux';

import { routerMiddleware } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';

import rootReducer from '../redux/root-reducer';

import rootSaga from '../redux/root-sagas';

import logger from 'redux-logger';

import * as Sentry from '@sentry/react';

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// Create a single instance- these three steps needed
// 1)
const sagaMiddleware = createSagaMiddleware();
// 1.2) adding logger to middleware
const middlewares = [sagaMiddleware];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
// 2)
const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(routerMiddleware(history), ...middlewares),
    sentryReduxEnhancer
  )
);
// 3)
sagaMiddleware.run(rootSaga);

export default store;
