/*** 
  Ref List of all the actions that can be called  
***/
export const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  //Check to see if the Users's Auth has persisted
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  NEW_MOBILE_PASSWORD_START: 'NEW_MOBILE_PASSWORD_START',
  NEW_MOBILE_PASSWORD_SUCCESS: 'NEW_MOBILE_PASSWORD_SUCCESS',
  NEW_MOBILE_PASSWORD_FAILURE: 'NEW_MOBILE_PASSWORD_FAILURE',

  NEW_WEB_PASSWORD_START: 'NEW_WEB_PASSWORD_START',
  NEW_WEB_PASSWORD_SUCCESS: 'NEW_WEB_PASSWORD_SUCCESS',
  NEW_WEB_PASSWORD_FAILURE: 'NEW_WEB_PASSWORD_FAILURE',
};

export default UserActionTypes;
