import * as React from 'react';
import { Link } from 'react-router-dom';
/**
 * Styles
 */
import Theme from '../../theme.styles';
import { CellContainer, Row } from './PatientRecords.styles';

/**
 * Components
 */

import { Grid, GridItem } from '../../components/Grid';
import { Action } from '../Action';

/**
 * A drawer container that slides out and overlays the main body
 */
const PatientRecordsRow = ({ rowData }) => {
  const formatDate = (string) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  };

  return (
    <Theme>
      <Row>
        <Grid>
          <GridItem columnSize={4}>
            <CellContainer>
              {' '}
              <Link to={'/patient/' + rowData._id}>
                <Action
                  icn={{
                    name: 'binoculars',
                    size: 'medium',
                    variant: 'tertiary',
                  }}
                />
              </Link>
              {rowData.name}
            </CellContainer>
          </GridItem>
          <GridItem columnSize={4}>
            <CellContainer>{rowData.breed}</CellContainer>
          </GridItem>
          <GridItem columnSize={4}>
            <CellContainer>{formatDate(rowData.dob)}</CellContainer>
          </GridItem>
        </Grid>
      </Row>
    </Theme>
  );
};

export default PatientRecordsRow;
