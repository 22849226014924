import * as React from 'react';

/**
 * Styles
 */
import Theme from '../../theme.styles';

/**
 * Components
 */
import { Grid, GridItem } from '../../components/Grid';
import {
  PatientRecordsHeaderContainer,
  CellContainer,
} from './PatientRecords.styles';
/**
 * A drawer container that slides out and overlays the main body
 */
const PatientRecordsHeader = ({ children }) => {
  return (
    <Theme>
      <PatientRecordsHeaderContainer>
        {' '}
        <Grid>
          <GridItem columnSize={4}>
            <CellContainer>Pet Name</CellContainer>
          </GridItem>
          <GridItem columnSize={4}>
            <CellContainer>Breed</CellContainer>
          </GridItem>
          <GridItem columnSize={4}>
            <CellContainer>DOB</CellContainer>
          </GridItem>
        </Grid>
      </PatientRecordsHeaderContainer>
    </Theme>
  );
};

export default PatientRecordsHeader;
