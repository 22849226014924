import * as React from 'react';

/**
 * Styles
 */

import { GridItemContainer } from './Grid.styles';

/**
 * A GridItem component
 */
const GridItem = ({ columnSize, centerAlignContent, children }) => {
  return (
    <GridItemContainer
      columnSize={columnSize}
      centerAlignContent={centerAlignContent}
    >
      {children}
    </GridItemContainer>
  );
};

export default GridItem;
