import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

/**
 * Actions
 */

import { fetchPatientsStart } from '../../redux/patient/patient.actions';

/**
 * Components
 */

import { Grid, GridItem } from '../../components/Grid';
import { Nav } from '../../components/Nav';
import { Text } from '../../components/Text';
import { PatientRecords } from '../../components/PatientRecords';
import { Spinner } from '../../components/Spinner';

/**
 * Styles
 */

// import {} from './Home.page.style.js';

const PatientProfilesPage = ({ loading, patientList, fetchPatientsStart }) => {
  useEffect(() => {
    if (!patientList) {
      const token = localStorage.getItem('jwt');
      fetchPatientsStart(token);
      return;
    }
  }, [patientList]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <main>
            <PatientRecords patientRecordsData={patientList} />
          </main>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPatientsStart: (token) => {
      dispatch(fetchPatientsStart(token));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.patientReducer.loading,
    patientList: state.patientReducer.patientList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientProfilesPage);
