import { takeEvery, call, put } from 'redux-saga/effects';

import axios from 'axios';

import { fetchDummyDataTypes } from './fetchData.types';

import { fetchDataSuccess } from './fetchData.actions';

function* asyncFetchRequest(action) {
  try {
    const url = `https://reqres.in/api/users/${action.payload}`;
    const response = yield call(() => axios.get(url));
    console.log(response);
    yield put(fetchDataSuccess(response.data.data.first_name));
  } catch (error) {
    console.log(error);
  }
}

export function* watchFetchDataSaga() {
  yield takeEvery(fetchDummyDataTypes.SEND_REQUEST, asyncFetchRequest);
}
