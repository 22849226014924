import React from 'react';

/**
 * Components
 */

import { EditPatientProfile } from '../../components/EditPatientProfile';
import { Nav } from '../../components/Nav';

/**
 * Styles
 */

import { SignUpContainer } from './EditPatientProfile.styles.js';

const EditPatientProfilePage = () => {
  return (
    <>
      <main>
        <SignUpContainer>
          <EditPatientProfile />
        </SignUpContainer>
      </main>
    </>
  );
};

export default EditPatientProfilePage;
