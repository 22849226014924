import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * Actions
 */

import { onNewMobilePasswordStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { FormInput } from '../Form-Input';
import { Button } from '../Button';

/**
 * Styles
 */
import {
  FormContainer,
  NewPasswordHeader,
  PasswordContainer,
  PasswordTitle,
  ButtonsBarContainer,
} from './NewMobilePassword.styles';

/**
 * SignUp Component
 */

const NewMobilePassword = ({ onNewMobilePasswordStart, loading }) => {
  let { token } = useParams();

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password should be longer than 8 characters')
        .required(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: (values) => {
      onNewMobilePasswordStart({ password: values.password, token });
    },
  });

  return (
    <PasswordContainer>
      <NewPasswordHeader>
        <PasswordTitle>Change Password</PasswordTitle>
        <span>Update password below</span>
      </NewPasswordHeader>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="password"
            type="password"
            id="password"
            label="Password"
            value={values.password}
            handleChange={handleChange}
            required
          />
          {touched.password && errors.password && <div>{errors.password}</div>}
          <FormInput
            name="confirmPassword"
            type="password"
            id="confirmPassword"
            label="Confirm Password"
            value={values.confirmPassword}
            handleChange={handleChange}
            required
          />
          {touched.confirmPassword && errors.confirmPassword && (
            <div>{errors.confirmPassword}</div>
          )}
        </FormContainer>
        <ButtonsBarContainer>
          <Button loading={loading} type="submit" margin>
            {' '}
            Update Password{' '}
          </Button>
        </ButtonsBarContainer>
      </form>
    </PasswordContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNewMobilePasswordStart: (newPasswordData) => {
      dispatch(onNewMobilePasswordStart(newPasswordData));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMobilePassword);
