import { takeLatest, put, all, call } from 'redux-saga/effects';

import axios from 'axios';

import { push } from 'connected-react-router';

import PatientActionTypes from './patient.types';

import {
  editPatientSuccess,
  editPatientFailure,
  fetchPatientsFailure,
  fetchPatientsSuccess,
  fetchPatientSuccess,
  fetchPatientLogSuccess,
  newPatientSuccess,
  newPatientFailure,
} from './patient.actions';

import { addAlertToList } from '../alert/alert.actions';

/**
 * GENERATOR FUNCTIONS
 **/

//This is a re-usable GENERATOR function-seriving email and google signin

export function* fetchPatients({ payload: token }) {
  try {
    const url = 'https://pet2vet-server.herokuapp.com/allpets';
    const response = yield call(() =>
      axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    );
    yield put(fetchPatientsSuccess(response.data.pets));
  } catch (error) {
    console.log(error);
    yield put(
      addAlertToList({
        description: 'Error Loading Patients',
        variant: 'error',
      })
    );
    yield put(fetchPatientsFailure(error));
  }
}

export function* newPatient({ payload: { newPatientData } }) {
  try {
    const { token, values } = newPatientData;
    const { name, breed, willowId, dob } = values;
    const url = 'https://pet2vet-server.herokuapp.com/newpet';
    const response = yield call(() =>
      axios.post(
        url,
        {
          name,
          breed,
          willowId,
          dob,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    );
    yield put(
      addAlertToList({
        description: 'New Patient Created',
        variant: 'success',
      })
    );
    yield put(newPatientSuccess(response.data.pet));
    yield put(push('/patientprofiles'));
  } catch ({ response, error }) {
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(newPatientFailure(error));
  }
}

export function* fetchPatient({ payload: { accessData } }) {
  const { patientid, token } = accessData;
  try {
    const patientUrl = `https://pet2vet-server.herokuapp.com/pet/${patientid}`;
    const logUrl = `https://pet2vet-server.herokuapp.com/pet/painlog/${patientid}`;
    const responsePatient = yield call(() =>
      axios.get(patientUrl, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    );
    const responseLog = yield call(() =>
      axios.get(logUrl, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    );
    yield put(fetchPatientSuccess(responsePatient.data.pet));
    yield put(fetchPatientLogSuccess(responseLog.data.painlog));
  } catch (error) {
    console.log(error);
    yield put(
      addAlertToList({
        description: 'Error Loading Patients',
        variant: 'error',
      })
    );
  }
}

export function* editPatient({ payload: { editPatientData } }) {
  try {
    const { token, values, patientid } = editPatientData;
    const { name, breed, willowId, dob } = values;
    const url = `https://pet2vet-server.herokuapp.com/editpet/${patientid}`;
    const response = yield call(() =>
      axios.put(
        url,
        {
          name,
          breed,
          willowId,
          dob,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    );
    yield put(
      addAlertToList({
        description: 'Patient Updated',
        variant: 'success',
      })
    );
    yield put(editPatientSuccess(editPatientData));
    yield put(push('/patientprofiles'));
  } catch ({ response, error }) {
    yield put(
      addAlertToList({
        description: response.data.error,
        variant: 'error',
      })
    );
    yield put(editPatientFailure(error));
  }
}

/**
 * Listeners
 **/

export function* onEditPatientStart() {
  yield takeLatest(PatientActionTypes.EDIT_PATIENT_START, editPatient);
}

export function* onFetchPatientsStart() {
  yield takeLatest(PatientActionTypes.FETCH_PATIENTS_START, fetchPatients);
}

export function* onNewPatientStart() {
  yield takeLatest(PatientActionTypes.NEW_PATIENT_START, newPatient);
}

export function* onFetchPatientStart() {
  yield takeLatest(PatientActionTypes.FETCH_PATIENT_START, fetchPatient);
}

/**
 * Main Listening SAGA
 **/
export function* userSagas() {
  yield all([
    call(onEditPatientStart),
    call(onFetchPatientStart),
    call(onFetchPatientsStart),
    call(onNewPatientStart),
  ]);
}
