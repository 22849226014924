import styled from 'styled-components';
import { spacing } from '../../config/styles/spacing';

export const MyAccountContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin: ${spacing.md} ${spacing.xl};

  @media screen and (max-width: 1100px) {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media screen and (max-width: 1100px) {
    margin: ${spacing.xs} ${spacing.xs};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  background-color: ${(props) => props.theme.colours.uiTwo};
  margin: ${spacing.lg} ${spacing.xxl};

  @media screen and (max-width: 1100px) {
    width: 90%;
    margin: auto;
    min-height: 400px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: ${spacing.lg} 0;
`;

export const ButtonWrapperMobile = styled.div`
  width: 100%;
  margin: ${spacing.lg} ${spacing.xxs};
`;

export const HeaderContainer = styled.div`
  padding: ${spacing.lg} ${spacing.lg};
`;
