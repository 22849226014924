import styled from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.md};
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));

  @media screen and (max-width: 800px) {
    margin: 0;
    margin-top: ${spacing.lg};
  }
`;

export const ImageContainer = styled.div``;

export const Image = styled.img`
  max-width: 6rem;

  @media screen and (max-width: 800px) {
    max-width: 4rem;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: 'Trocchi' !important;
  font-size: 2rem !important;
  margin: 0;
  line-height: 1rem;
  color: #2e6ca3;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem !important;
  }
`;
