import React from 'react';

/**
 * Components
 */

import { Text } from '../../components/Text';

/**
 * Styles
 */

const MobileNewPasswordSuccess = () => {
  return (
    <main>
      <Text bold variant="Delta" textAlignment="centre">
        Your Password has successfully been changed
      </Text>
      <Text textAlignment="centre">
        Please close this window and head back to the mobile App
      </Text>
    </main>
  );
};

export default MobileNewPasswordSuccess;
