import React from 'react';

/**
 * Components
 */

import { Nav } from '../../components/Nav';
import { Text } from '../../components/Text';

/**
 * Styles
 */
import { HeaderContainer } from './CalendarPage.styles';

const CalendarPage = () => {
  return (
    <>
      <main>
        <HeaderContainer>
          <Text bold variant="delta">
            User Calendar
          </Text>
          <Text>Coming Soon........</Text>
        </HeaderContainer>
      </main>
    </>
  );
};

export default CalendarPage;
