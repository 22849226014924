import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Actions
 */

import { onResetPasswordStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { FormInput } from '../Form-Input';
import { Button } from '../Button';

/**
 * Styles
 */
import {
  FormContainer,
  ResetWebPasswordContainer,
  ResetWebPasswordHeader,
  ResetWebPasswordTitle,
  ButtonsBarContainer,
} from './ResetWebPassword.styles';

/**
 * ResetWebPassword Component
 */

const ResetWebPassword = ({ currentUser, onResetPasswordStart, loading }) => {
  const history = useHistory();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Required'),
    }),
    onSubmit: (values) => {
      onResetPasswordStart(values);
    },
  });

  return (
    <ResetWebPasswordContainer>
      <ResetWebPasswordHeader>
        <ResetWebPasswordTitle>Change Password</ResetWebPasswordTitle>
        <span>Update password below</span>
      </ResetWebPasswordHeader>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="email"
            type="email"
            id="email"
            label="Email"
            value={values.email}
            handleChange={handleChange}
            required
          />
          {touched.email && errors.email && <div>{errors.email}</div>}
        </FormContainer>
        <ButtonsBarContainer>
          <Button loading={loading} type="submit" margin>
            {' '}
            Reset Password{' '}
          </Button>
          {!currentUser && (
            <Button handleClick={() => history.push('/webuser/signin')} margin>
              {' '}
              Sign In?{' '}
            </Button>
          )}
        </ButtonsBarContainer>
      </form>
    </ResetWebPasswordContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPasswordStart: (email) => {
      dispatch(onResetPasswordStart(email));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetWebPassword);
