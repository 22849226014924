import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Actions
 */

import { onSignUpStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { FormInput } from '../Form-Input';
import { Button } from '../Button';

/**
 * Styles
 */
import {
  FormContainer,
  SignUpContainer,
  SignUpHeader,
  SignUpTitle,
  ButtonsBarContainer,
} from './Sign-Up.styles';

/**
 * SignUp Component
 */

const SignUp = ({ onSignUpStart, loading }) => {
  const history = useHistory();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      telephone: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Required'),
      password: Yup.string()
        .min(8, 'Password should be longer than 8 characters')
        .required(),
      name: Yup.string()
        .min(2, 'Name should be longer than 2 characters')
        .required(),
      telephone: Yup.string()
        .min(10, 'Telephone number should be longer')
        .max(11, 'Telephone number should be shorter')
        .required(),
    }),
    onSubmit: (values) => {
      onSignUpStart(values);
    },
  });

  return (
    <SignUpContainer>
      <SignUpHeader>
        <SignUpTitle>Create a new account</SignUpTitle>
        <span>Sign up below</span>
      </SignUpHeader>

      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="email"
            type="email"
            id="email"
            label="Email"
            value={values.email}
            handleChange={handleChange}
            required
          />
          {touched.email && errors.email && <div>{errors.email}</div>}
          <FormInput
            name="password"
            type="password"
            id="password"
            label="Password"
            value={values.password}
            handleChange={handleChange}
            required
          />
          {touched.password && errors.password && <div>{errors.password}</div>}
          <FormInput
            name="name"
            type="name"
            id="name"
            label="Name"
            value={values.name}
            handleChange={handleChange}
            required
          />
          {touched.name && errors.name && <div>{errors.name}</div>}
          <FormInput
            name="telephone"
            type="telephone"
            id="telephone"
            label="Telephone"
            value={values.telephone}
            handleChange={handleChange}
            required
          />
          {touched.telephone && errors.telephone && (
            <div>{errors.telephone}</div>
          )}
        </FormContainer>
        <ButtonsBarContainer>
          <Button loading={loading} type="submit">
            {' '}
            Create New User{' '}
          </Button>
        </ButtonsBarContainer>
      </form>
    </SignUpContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignUpStart: (newUserInformation) => {
      dispatch(onSignUpStart(newUserInformation));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
