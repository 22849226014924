import styled, { css } from 'styled-components';
import { basefontsize } from '../../config/styles/typography';

const lgSize = '100px';
const xsSize = '40px';
const letterSpacing = '0.15rem';

const baseAvatarStyles = css`
  background-color: ${(props) => props.theme.colours.brandTwo};
  color: ${(props) => props.theme.colours.uiTwo};
  &:hover {
    background-color: ${(props) => props.theme.colours.uiOne};
  }
`;
const blackAvatarStyles = css`
  background-color: black;
  color: ${(props) => props.theme.colours.uiTwo};
`;

const lightBlueAvatarStyles = css`
  background-color: ${(props) => props.theme.colours.brandTwo};
  color: ${(props) => props.theme.colours.uiTwo};
`;

// const whiteAvatarStyles = css`
//   background-color: colours.$brand-one;
//   color: colours.$brand-three;
// `;

const getAvatarStyles = (props) => {
  switch (props.colour) {
    default:
      return baseAvatarStyles;
    case 'black':
      return blackAvatarStyles;
    case 'uiOne':
      return lightBlueAvatarStyles;
    // case 'white':
    //   return whiteAvatarStyles;
  }
};

export const AvatarContainer = styled.div`
  box-sizing: border-box;
  height: ${(props) => (props.small ? xsSize : lgSize)};
  width: ${(props) => (props.small ? xsSize : lgSize)};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    margin: 0;
    margin-top: 5px;
  }
`;

export const AvatarText = styled.span`
  align-items: center;
  border-radius: ${(props) => props.theme.corners.round};
  display: flex;
  font-size: ${(props) => (props.small ? '0.75rem' : '2rem')};
  font-weight: 400;
  height: inherit;
  justify-content: center;
  letter-spacing: ${letterSpacing};
  text-align: center;
  text-indent: ${letterSpacing};
  width: inherit;
  ${getAvatarStyles};
`;
