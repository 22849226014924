import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Components
 */

import { Drawer } from '../../components/Drawer';
import { Button } from '../../components/Button';

/**
 * Styles
 */

import { DrawerLinkContainer } from './Nav.styles';
import { useEffect } from 'react';

/**
 * Drawer  Links
 */

const drawerLinks = [
  { label: 'Dashboard', value: '/dashboard' },
  { label: 'New Patient Profile', value: '/newpatient' },
  { label: 'Patient Profiles', value: '/patientprofiles' },
  { label: 'Devices', value: '/devices' },
  { label: 'Calendar', value: '/calendar' },
  { label: 'File Cabinent', value: '/filestorage' },
];

const Nav = ({ toggleBurger }) => {
  let history = useHistory();
  const [updateToggle, setToggle] = useState(toggleBurger);
  const handleRoute = (link) => {
    history.push(`${link}`);
  };

  useEffect(() => {
    setToggle(toggleBurger);
  }, [toggleBurger]);
  return (
    <nav>
      <Drawer toggleBurger={updateToggle}>
        <DrawerLinkContainer>
          {drawerLinks.map((link) => (
            <li key={link.label}>
              <Button
                fullWidthButton
                variant="transparent"
                icn={{ name: `${link.label}` }}
                icnPosition="right"
                noPadding
                handleClick={() => {
                  handleRoute(link.value);
                  setToggle(!updateToggle);
                }}
              >
                {link.label}
              </Button>
            </li>
          ))}
        </DrawerLinkContainer>
      </Drawer>
    </nav>
  );
};

export default Nav;
