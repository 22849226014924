import AlertActionTypes from './alert.types';
import { deleteAlert } from './alert.utils';

const INITIAL_STATE = {
  alertList: [],
};
/*** 
Listens for Actions
***/
const alertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //CASES have been stacked for same return function
    case AlertActionTypes.ADD_ALERT:
      return {
        ...state,
        alertList: state.alertList.concat(action.payload),
      };
    case AlertActionTypes.REM_ALERT:
      return {
        ...state,
        alertList: deleteAlert(state.alertList, action.payload),
      };
    case AlertActionTypes.REM_ALL_ALERT:
      return {
        ...state,
        alertList: [],
      };
    default:
      return state;
  }
};

export default alertReducer;
