import React from 'react';

/**
 * Components
 */

import * as Icons from '../../assets/icons';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';

/**
 * Styles
 */

import Theme from '../../theme.styles';
import {
  CustomButtonContainer,
  IconContainer,
  IconWrapper,
  LoadingIconWrapper,
  IconWrapperOuter,
  SPAN,
} from './Button.styles';

const Button = ({
  children,
  icn,
  icnPosition = 'left',
  handleClick,
  loading,
  ...props
}) => {
  const Icon = Icons[icn && icn.name.replace(/ /g, '').toLowerCase()];
  return (
    <Theme>
      {!loading ? (
        <CustomButtonContainer
          className="Button"
          {...props}
          onClick={handleClick}
        >
          {icn ? (
            icnPosition === 'left' ? (
              <IconWrapperOuter>
                <IconWrapper>
                  <IconContainer>
                    <Icon />
                  </IconContainer>
                </IconWrapper>
              </IconWrapperOuter>
            ) : null
          ) : null}
          <SPAN>{children}</SPAN>
          {icn ? (
            icnPosition === 'right' ? (
              <IconWrapperOuter>
                <IconWrapper>
                  <IconContainer>
                    <Icon />
                  </IconContainer>
                </IconWrapper>
              </IconWrapperOuter>
            ) : null
          ) : null}
        </CustomButtonContainer>
      ) : (
        <CustomButtonContainer
          className="Button"
          {...props}
          onClick={handleClick}
          disabled
        >
          <IconWrapperOuter>
            <LoadingIconWrapper>
              <IconContainer>
                <LoadingIcon />
              </IconContainer>
            </LoadingIconWrapper>
          </IconWrapperOuter>
          <SPAN>Loading...</SPAN>
        </CustomButtonContainer>
      )}
    </Theme>
  );
};

export default Button;
