export const updateViewingPatientData = (oldPatient, updatedPatient) => {
  const { values } = updatedPatient;
  const { name, breed, willowId, dob } = values;
  return { ...oldPatient, name, breed, dob, willowId };
};

export const updatePatientList = (patientList, editPatientData) => {
  const { values, patientid } = editPatientData;
  const { name, breed, willowId, dob } = values;
  return patientList.map((item) => {
    if (item._id === patientid) {
      console.log('found');
      return { ...item, name, breed, dob, willowId };
    }
    return item;
  });
};
