import styled from 'styled-components';
import { spacing } from '../../config/styles/spacing';

export const PRContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => props.theme.borders.uiTwo};
  margin: 65px 5px 0 5px;
`;
export const PatientRecordsHeaderContainer = styled.div`
  background-color: ${(props) => props.theme.colours.brandTwo};
  color: ${(props) => props.theme.colours.uiTwo};
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  border-bottom: ${(props) => props.theme.borders.uiTwo};
`;
export const CellContainer = styled.div`
  border-right: 1px solid lightgray;
  margin-left: ${spacing.md};
`;

export const PagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const IconWrapper = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  margin-right: ${spacing.lg};
  fill: ${(props) => props.theme.colours.brandTwo};
  &:hover {
    fill: ${(props) => props.theme.colours.uiOne};
  }

  @media screen and (max-width: 800px) {
    width: 20px;
    padding: ${spacing.xxs};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;
