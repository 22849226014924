import React from 'react';

/**
 * Components
 */

import { SignIn } from '../../components/Sign-In';
import { Image } from '../../components/Image';

/**
 * Styles
 */

import { SignInContainer } from './SignIn.page.style.js';

const SignInPage = () => {
  return (
    <main>
      <SignInContainer>
        {/* <Image
          src="https://media.giphy.com/media/Oe250JH0HnVss/giphy.gif"
          aspectRatio={2.5}
        /> */}
        <SignIn />
      </SignInContainer>
    </main>
  );
};

export default SignInPage;
