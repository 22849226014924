import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

/**
 * Actions
 */

import { remAlertToList } from '../../redux/alert/alert.actions';

/**
 * Components
 */
import { Button } from '../../components/Button';
import { Action } from '../Action';
import { Grid, GridItem } from '../Grid';
import * as Icons from '../../assets/icons';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import {
  ActionContainer,
  NotificationContainer,
  RelativeContainer,
  AlertOuterContainer,
  AlertMessage,
  Message,
  IconWrapper,
  IconContainer,
} from './Alert.styles';

/**
 * Variants
 */
const Variant = {
  error: 'inforound',
  info: 'inforound',
  success: 'successtick',
  warning: 'warningtriangle',
};

/**
 * Alert component
 */
const Alert = ({ alertList, remAlertToList, autoDelete }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && alertList.length) {
        remAlertToList(alertList[0].description);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [alertList]);

  const renderIcon = (icon) => {
    const Icon = Icons[Variant[icon]];
    return <Icon />;
  };

  return (
    <Theme>
      <NotificationContainer>
        {alertList.map((alert, i) => (
          <RelativeContainer key={i} variant={alert.variant}>
            <AlertOuterContainer>
              <Grid>
                <GridItem columnSize="1" centerAlignContent>
                  <div>
                    <IconWrapper>
                      <IconContainer>{renderIcon(alert.variant)}</IconContainer>
                    </IconWrapper>
                  </div>
                </GridItem>
                <GridItem columnSize="10" centerAlignContent>
                  <AlertMessage>
                    <Message>{alert.description}</Message>
                  </AlertMessage>
                </GridItem>
                <GridItem columnSize="1">
                  <ActionContainer>
                    <Action
                      icn={{
                        name: 'closeround',
                        size: 'small',
                        variant: 'inverted',
                      }}
                      handleClick={() => remAlertToList(alert.description)}
                    />
                  </ActionContainer>
                </GridItem>
              </Grid>
            </AlertOuterContainer>
          </RelativeContainer>
        ))}
      </NotificationContainer>
    </Theme>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    remAlertToList: (item) => {
      dispatch(remAlertToList(item));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    alertList: state.alertReducer.alertList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
