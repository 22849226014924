import styled, { css } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

const buttonStyles = css`
  background-color: ${(props) => props.theme.colours.brandTwo};
  color: white;
  border: 1px solid ${(props) => props.theme.colours.brandTwo};
  fill: ${(props) => props.theme.colours.uiTwo};

  &:hover {
    background-color: ${(props) => props.theme.colours.uiTwo};
    color: ${(props) => props.theme.colours.uiOne};
    border: 1px solid black;
    fill: ${(props) => props.theme.colours.uiOne};
  }
`;

const invertedButtonStyles = css`
  background-color: ${(props) => props.theme.colours.lightBlue};
  color: ${(props) => props.theme.colours.uiOne};
  fill: ${(props) => props.theme.colours.uiOne};
  border: none;
  /* border: 1px solid black; */

  &:hover {
    background-color: black;
    color: ${(props) => props.theme.colours.uiTwo};
    fill: ${(props) => props.theme.colours.uiTwo};
    border: none;
  }
`;

const transparentButtonStyles = css`
  background-color: Transparent;
  background-repeat: no-repeat;
  color: white;
  fill: white;
  border: none;

  &:hover {
    background-color: lightgrey;
    color: ${(props) => props.theme.colours.uiOne};
    fill: ${(props) => props.theme.colours.uiOne};
    border: none;
  }
`;

const icnOnlyButtonStyles = css`
  background-color: Transparent;
  background-repeat: no-repeat;
  color: white;
  fill: white;
  border: none;
  min-width: auto;

  &:hover {
    color: ${(props) => props.theme.colours.uiOne};
    fill: ${(props) => props.theme.colours.uiOne};
    border: none;
  }
`;

const getButtonStyles = (props) => {
  switch (props.variant) {
    default:
      return buttonStyles;
    case 'inverted':
      return invertedButtonStyles;
    case 'transparent':
      return transparentButtonStyles;
    case 'iconOnly':
      return icnOnlyButtonStyles;
  }
};

const fullWidthButton = (props) => {
  if (props.fullWidthButton) {
    return css`
      width: 100%;
    `;
  }
};

export const IconWrapperOuter = styled.div`
  transform: translateX(-${spacing.md});
  z-index:${zIndex.above};
`;
export const SPAN = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
`;

export const LoadingIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  vertical-align: middle;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  transform-origin: center center;
  animation: rotation 1.5s infinite linear;

  @media screen and (max-width: 800px) {
    width: 16px;
    height: 16px;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: ${spacing.md};
  vertical-align: middle;

  @media screen and (max-width: 800px) {
    width: 20px;
    padding: ${spacing.xxs};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
`;

export const CustomButtonContainer = styled.button`
  display: inline-flex;
  justify-content: space-between;
  white-space: nowrap;
  min-width: 10.3rem;
  width: auto;
  height: 3.125rem;
  line-height: 3.125rem;
  padding: 0 ${(props) => (props.noPadding ? 0 : '2.188rem')};
  margin: ${(props) => (props.margin ? `${spacing.sm}` : 0)};
  font-size: ${(props) => props.theme.fontSizes.small};
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  align-items: center;
  vertical-align: middle;

  ${getButtonStyles}
  ${fullWidthButton}
`;
