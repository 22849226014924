import styled from 'styled-components';

export const EditPatientContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

export const EditPatientTitle = styled.h2`
  margin: 10px 0;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const EditPatientHeader = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
`;
