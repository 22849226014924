import * as React from 'react';

/**
 * Styles
 */
import { GridContainer } from './Grid.styles';

/**
 * A Grid component
 */
const Grid = ({ children }) => {
  return <GridContainer>{children}</GridContainer>;
};

export default Grid;
