/*** 
  Ref List of all the actions that can be called  
***/
export const AlertActionTypes = {
  ADD_ALERT: 'ADD_ALERT',
  REM_ALERT: 'REM_ALERT',
  REM_ALL_ALERT: 'REM_ALL_ALERT',
};

export default AlertActionTypes;
