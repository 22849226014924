import styled, { css } from 'styled-components';

import {
  typography,
  fontBold,
  fontMedium,
} from '../../config/styles/typography';

const getTextAlignment = (props) => {
  switch (props.textAlignment) {
    default:
      return css`
        text-align: left;
      `;
    case 'center':
      return css`
        text-align: center;
      `;
    case 'right':
      return css`
        text-align: right;
      `;
  }
};

const getVariantStyles = (props) => {
  switch (props.variant) {
    default:
      return;
    case 'alpha':
      return typography.alpha;
    case 'beta':
      return typography.beta;
    case 'gamma':
      return typography.gamma;
    case 'delta':
      return typography.delta;
    case 'epilson':
      return typography.epilson;
    case 'intro':
      return typography.intro;
    case 'p':
      return typography.p;
    case 'small':
      return typography.small;
    case 'xsmall':
      return typography.xsmall;
  }
};

export const StyledText = styled.div`
  margin: 0;
  text-transform: ${(props) => (props.upperCase ? 'uppercase' : 'auto')};
  ${getTextAlignment}
  ${getVariantStyles}
  font-weight:${(props) => (props.bold ? fontBold : 'inherit')}
`;
