/*** 
The actions
***/

import AlertActionTypes from './alert.types';

/*** 
Add Alert
***/

export const addAlertToList = (alert) => ({
  type: AlertActionTypes.ADD_ALERT,
  payload: alert,
});

export const remAlertToList = (alert) => ({
  type: AlertActionTypes.REM_ALERT,
  payload: alert,
});

export const remAllAlertList = () => ({
  type: AlertActionTypes.REM_ALL_ALERT,
});
