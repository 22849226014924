import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
/**
 * Components
 */

import { Nav } from '../../components/Nav';

/**
 * Styles
 */

const HomePage = ({ currentUser }) => {
  const history = useHistory();

  useEffect(() => {
    if (!currentUser) {
      history.push('/webuser/signin');
    }
  });

  return (
    <>
      <main></main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps)(HomePage);
