/*** 
The actions themselves 
***/

import PatientActionTypes from './patient.types';

/*** 
FETCH ALL PATIENTS
***/

export const fetchPatientsStart = (token) => ({
  type: PatientActionTypes.FETCH_PATIENTS_START,
  payload: token,
});

export const fetchPatientsSuccess = (patients) => ({
  type: PatientActionTypes.FETCH_PATIENTS_SUCCESS,
  payload: patients,
});

export const fetchPatientsFailure = (error) => ({
  type: PatientActionTypes.FETCH_PATIENTS_FAILURE,
  payload: error,
});

/*** 
CREATE NEW PATIENT
***/

export const newPatientStart = (newPatientData) => ({
  type: PatientActionTypes.NEW_PATIENT_START,
  payload: { newPatientData },
});

export const newPatientSuccess = (patient) => ({
  type: PatientActionTypes.NEW_PATIENT_SUCCESS,
  payload: patient,
});

export const newPatientFailure = (error) => ({
  type: PatientActionTypes.NEW_PATIENT_FAILURE,
  payload: error,
});

/*** 
EDIT NEW PATIENT
***/

export const editPatientStart = (editPatientData) => ({
  type: PatientActionTypes.EDIT_PATIENT_START,
  payload: { editPatientData },
});

export const editPatientSuccess = (res) => ({
  type: PatientActionTypes.EDIT_PATIENT_SUCCESS,
  payload: res,
});

export const editPatientFailure = (error) => ({
  type: PatientActionTypes.EDIT_PATIENT_FAILURE,
  payload: error,
});

/*** 
FETCH SINGLE PATIENT RECORD
***/

export const fetchPatientStart = (accessData) => ({
  type: PatientActionTypes.FETCH_PATIENT_START,
  payload: { accessData },
});

export const fetchPatientSuccess = (patient) => ({
  type: PatientActionTypes.FETCH_PATIENT_SUCCESS,
  payload: patient,
});

export const fetchPatientLogSuccess = (patientLogs) => ({
  type: PatientActionTypes.FETCH_PATIENTLOG_SUCCESS,
  payload: patientLogs,
});

export const fetchPatientFailure = (error) => ({
  type: PatientActionTypes.FETCH_PATIENT_FAILURE,
  payload: error,
});
