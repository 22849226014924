import styled from 'styled-components';
import { spacing } from '../../config/styles/spacing';

export const SignUpContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: ${spacing.xs};

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

export const SignUpTitle = styled.h2`
  margin: 10px 0;
`;

export const ButtonsBarContainer = styled.div`
  width: 400px;
  margin: auto;
`;

export const SignUpHeader = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
`;
