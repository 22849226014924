import * as React from 'react';

/**
 * Styles
 */
import Theme from '../../theme.styles';
// import { TableContainer } from './PatientRecords.styles';

/**
 * Components
 */

import { PatientRecordsRow } from '../PatientRecords';

/**
 * A drawer container that slides out and overlays the main body
 */
const PatientRecordsBody = ({ patientRecordsData }) => {
  return (
    <Theme>
      <div>
        {patientRecordsData &&
          patientRecordsData.map((record, index) => (
            <PatientRecordsRow key={index} rowData={record} />
          ))}
      </div>
    </Theme>
  );
};

export default PatientRecordsBody;
