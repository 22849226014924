import styled, { css } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { basefontsize } from '../../config/styles/typography';

const lgSize = '100px';
const xsSize = '40px';
const letterSpacing = '0.15rem';

export const ChartWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: ${spacing.lg};
`;

export const ChartContainer = styled.div`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: ${(props) => props.windowSize.width / 1.5}px;
  height: ${(props) => props.windowSize.height / 2 + lgSize}px;
  background-color: #fff;
`;
