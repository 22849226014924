import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

/**
 * Components
 */

import { Avatar } from '../../components/Avatar';
import { EditUser } from '../../components/EditUser';
import { Button } from '../../components/Button';
import { Nav } from '../../components/Nav';
import { UserProfileCard } from '../../components/UserProfileCard';
import { ResetWebPassword } from '../../components/ResetWebPassword';
import { SignUp } from '../../components/Sign-Up';
import { Text } from '../../components/Text';

/**
 * Hooks
 */

import { useWindowSize } from '../../hooks/useWindowSize';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import {
  ButtonWrapper,
  ButtonWrapperMobile,
  HeaderContainer,
  LeftContent,
  MyAccountContainer,
  RightContent,
} from './MyAccount.styles';

const MyAccountPage = ({ currentUser }) => {
  const windowSize = useWindowSize();
  const { width } = windowSize;
  const mobile = width < 800;

  const [selectedRightContent, setRightContent] = useState('My Account');

  const menuItems = [
    'My Account',
    'My Details',
    'Create New User',
    'Edit Details',
    'Change Password',
    'Settings',
  ];

  const renderMyAccount = () => {
    return (
      <>
        <Avatar large>{currentUser && currentUser.name}</Avatar>
        <div>Hi, {currentUser && currentUser.name}</div>
      </>
    );
  };

  const renderRighContent = () => {
    switch (selectedRightContent) {
      case 'My Details':
        return <UserProfileCard userInfo={currentUser} />;
      case 'Create New User':
        return <SignUp />;
      case 'Edit Details':
        return <EditUser />;
      case 'Change Password':
        return <ResetWebPassword />;
      default:
        return renderMyAccount();
    }
  };

  const renderDesktopNav = () =>
    menuItems.map((item) => (
      <ButtonWrapper key={item}>
        <Button
          key={item}
          icn={{ name: 'chevronright' }}
          icnPosition="right"
          variant="inverted"
          fullWidthButton
          handleClick={() => setRightContent(item)}
        >
          {item}
        </Button>
      </ButtonWrapper>
    ));

  const renderMobileNav = () =>
    menuItems.map((item) => (
      <ButtonWrapperMobile key={item}>
        <Button
          key={item}
          variant="inverted"
          handleClick={() => setRightContent(item)}
        >
          {item}
        </Button>
      </ButtonWrapperMobile>
    ));

  return (
    <>
      <main>
        <Theme>
          <HeaderContainer>
            <Text variant="delta" bold>
              My Account
            </Text>
          </HeaderContainer>
          <MyAccountContainer>
            <LeftContent>
              {width < 1100 ? renderMobileNav() : renderDesktopNav()}
            </LeftContent>
            <RightContent>{renderRighContent()}</RightContent>
          </MyAccountContainer>
        </Theme>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps)(MyAccountPage);
