import React from 'react';

/**
 * Components
 */

import { Text } from '../../components/Text';
/**
 * Styles
 */
import { HeaderContainer } from './DevicesPages.styles';

const DevicesPage = () => {
  return (
    <>
      <main>
        <HeaderContainer>
          <Text bold variant="delta">
            Patient Devices
          </Text>
          <Text>Coming Soon........</Text>
        </HeaderContainer>
      </main>
    </>
  );
};

export default DevicesPage;
