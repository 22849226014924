import { useState } from 'react';

const useMonthlyPainLogFilter = () => {
  const [painLogRaw, setPainLogRaw] = useState([]);

  const checkImpression = (impression) => {
    switch (impression) {
      default:
        return 1;
      case 'fair':
        return 2;
      case 'good':
        return 3;
      case 'vgood':
        return 4;
      case 'excellent':
        return 5;
    }
  };
  const normalize = () => {
    painLogRaw.map((item) => {
      item.overall_impress = checkImpression(item.overall_impress);
    });
  };

  normalize();

  let painFuncArr = [];

  const filterPainAndFuncData = () => {
    painLogRaw.map((item) => {
      const {
        mean,
        least,
        current,
        worst,
        can_climb,
        can_run,
        can_walk,
        stand_up,
        enjoyment_life,
        gen_activity,
        overall_impress,
        createdAt,
      } = item;
      let total =
        mean +
        least +
        current +
        worst +
        can_climb +
        can_run +
        can_walk +
        stand_up +
        enjoyment_life +
        gen_activity +
        overall_impress;
      painFuncArr.push({
        painScore: mean + least + current + worst,
        functionScore:
          can_climb +
          can_run +
          can_walk +
          stand_up +
          enjoyment_life +
          gen_activity,
        overall_impress,
        total,
        date: createdAt,
      });
    });
  };

  filterPainAndFuncData();

  // console.log(painFuncArr);

  //   [
  //     {
  //       painScore: 15,
  //       functionScore: 38,
  //       overall_impress: 1,
  //       total: 54,
  //       date: '2020-06-12T13:18:10.420Z'
  //     },
  //     {
  //       painScore: 9,
  //       functionScore: 13.5,
  //       overall_impress: 2,
  //       total: 24.5,
  //       date: '2020-09-17T06:48:13.947Z'
  //     }
  //   ]

  let magnitudePainFuncArr = [];
  const magnitudePainFunction = () => {
    painFuncArr.map((item, index) => {
      const { painScore, functionScore, overall_impress, total, date } = item;
      if (index > 0) {
        magnitudePainFuncArr.push({
          painScoreDelta: painScore - painFuncArr[0].painScore,
          functionScoreDelta: functionScore - painFuncArr[0].functionScore,
          overall_impressDelta:
            overall_impress - painFuncArr[0].overall_impress,
          totalDelta: total - painFuncArr[0].total,
          date,
        });
      }
    });
  };

  magnitudePainFunction();
  // console.log(magnitudePainFuncArr);

  //   [
  //     {
  //       painScoreDelta: -6,
  //       functionScoreDelta: -24.5,
  //       overall_impressDelta: 1,
  //       totalDelta: -29.5,
  //       date: '2020-09-17T06:48:13.947Z'
  //     }
  //   ]

  return {
    setPainLogRaw,
    painFuncArr,
    magnitudePainFuncArr,
  };
};

export default useMonthlyPainLogFilter;
