/*** 
  Ref List of all the actions that can be called  
***/
export const PatientActionTypes = {
  FETCH_PATIENTS_START: 'FETCH_PATIENTS_START',
  FETCH_PATIENTS_SUCCESS: 'FETCH_PATIENTS_SUCCESS',
  FETCH_PATIENTS_FAILURE: 'FETCH_PATIENTS_FAILURE',

  NEW_PATIENT_START: 'NEW_PATIENT_START',
  NEW_PATIENT_SUCCESS: 'NEW_PATIENT_SUCCESS',
  NEW_PATIENT_FAILURE: 'NEW_PATIENT_FAILURE',

  FETCH_PATIENT_START: 'FETCH_PATIENT_START',
  FETCH_PATIENT_SUCCESS: 'FETCH_PATIENT_SUCCESS',
  FETCH_PATIENTLOG_SUCCESS: 'FETCH_PATIENTLOG_SUCCESS',
  FETCH_PATIENT_FAILURE: 'FETCH_PATIENT_FAILURE',

  EDIT_PATIENT_START: 'EDIT_PATIENT_START',
  EDIT_PATIENT_SUCCESS: 'EDIT_PATIENT_SUCCESS',
  EDIT_PATIENT_FAILURE: 'EDIT_PATIENT_FAILURE',
};

export default PatientActionTypes;
