/// ----------------------------------------------------------------------

/// Spacing

/// ----------------------------------------------------------------------
export const spacing = {
  xxxxs: '0', // 0px
  xxxs: '0.005rem', // 1px
  xxs: '0.25rem', // 4px
  xs: '0.5rem', // 8px
  sm: '0.75rem', // 12px
  md: '1rem ', // 16px
  lg: '1.5rem', // 24px
  xl: '2rem', // 32px
  xxl: '3rem ', // 48px
  xxxl: '6rem', // 96px
};
