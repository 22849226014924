import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

export const HeaderContainer = styled.div`
  top: 0;
  position: fixed;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colours.uiTwo};
  z-index: ${zIndex.pageHeader};

  @media screen and (max-width: 800px) {
    height: 60px;
    padding: 10px 0;
    margin-bottom: ${spacing.md};
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: ${spacing.xxl};

  @media screen and (max-width: 800px) {
    padding: ${spacing.xxxxs};
    margin-left: ${spacing.xs};
  }
`;

export const LogoContainer = styled(Link)`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const OptionsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${spacing.xxl};

  @media screen and (max-width: 800px) {
    width: 80%;
    margin-right: ${spacing.md};
  }
`;

export const OptionLink = styled.div`
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  margin-right: ${spacing.lg};
  fill: ${(props) => props.theme.colours.brandTwo};
  &:hover {
    fill: ${(props) => props.theme.colours.uiOne};
  }

  @media screen and (max-width: 800px) {
    width: 20px;
    height: 20px;
    padding: ${spacing.xxs};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  padding: ${spacing.xxs};
  border: ${(props) => props.theme.borders.uiOne};
  border-radius: 20px;
  margin: ${spacing.sm};
`;

export const UserNameText = styled.p`
  margin-left: ${spacing.xs};
`;
