import React from 'react';

/**
 * Styles
 */

import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
} from './ErrorBoundary.styles';

const ErrorBoundary = () => {
  return (
    <ErrorImageOverlay>
      <ErrorImageContainer imageUrl={'https://i.imgur.com/hkRuanu.png'} />
      <ErrorImageText>Sorry, this page is broken</ErrorImageText>
    </ErrorImageOverlay>
  );
};

export default ErrorBoundary;
