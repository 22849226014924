/// ----------------------------------------------------------------------

/// Typography

/// ----------------------------------------------------------------------

import styled, { css } from 'styled-components';

/// -------------------
/// Base config
/// -------------------

const basefontsize = '16px';
const baseLineHeight = '1.5';

// $font-family: ('Scto Grotesk B', sans-serif) !default;
// $font-family--alt: ('Scto Grotesk B', sans-serif) !default;

const fontThin = '100';
const fontLight = '300';
const fontRegular = '400';
const fontMedium = '500';
const fontBold = '600';
const fontBlack = '900';

/// -------------------
/// Type styles
/// -------------------

const typography = {
  alpha: css`
    font-size: 3rem;
    font-weight: ${fontRegular};
  `,
  beta: css`
    font-size: 2.25rem;
    font-weight: ${fontRegular};
  `,
  gamma: css`
    font-size: 2rem;
    font-weight: ${fontRegular};
  `,
  delta: css`
    font-size: 1.75rem;
    font-weight: ${fontRegular};
  `,
  epilson: css`
    font-size: 1.5rem;
    font-weight: ${fontRegular};
  `,
  intro: css`
    font-size: 1.25rem;
    font-weight: ${fontLight};
  `,
  p: css`
    font-size: 1rem;
    font-weight: ${fontLight};
  `,
  small: css`
    font-size: 0.85rem;
    font-weight: ${fontLight};
  `,
  xsmall: css`
    font-size: 0.62rem;
    font-weight: ${fontLight};
  `,
};

export { typography, fontBold, basefontsize };
