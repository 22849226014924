import React from 'react';

/**
 * Components
 */

import * as Icons from '../../assets/icons';

/**
 * Styles
 */

import Theme from '../../theme.styles';
import {
  IconButton,
  IconContainer,
  IconWrapper,
  IconWrapperOuter,
} from './Action.styles';

const Action = ({ icn, handleClick }) => {
  const Icon = Icons[icn && icn.name.replace(/ /g, '').toLowerCase()];
  return (
    <Theme>
      <IconButton onClick={handleClick} icn={icn}>
        <IconWrapper icn={icn}>
          <IconContainer>
            <Icon />
          </IconContainer>
        </IconWrapper>
      </IconButton>
    </Theme>
  );
};

export default Action;
