import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * Actions
 */

import { fetchPatientStart } from '../../redux/patient/patient.actions';

/**
 * Hooks
 */

import { useMonthlyPainLogFilter } from '../../hooks/useMonthlyPainLogFilter';
import { useWindowSize } from '../../hooks/useWindowSize';

/**
 * Components
 */

import { ProfileCard } from '../../components/ProfileCard';
import { Chart } from '../../components/Chart';
import { Image } from '../../components/Image';
import { Spinner } from '../../components/Spinner';
import { Text } from '../../components/Text';

/**
 * Styles
 */

// import { SignUpContainer } from './NewPatient.page.style';

const PatientProfilePage = ({
  loading,
  fetchPatientStart,
  patientInfo,
  painLogData,
}) => {
  /**
   * Mobile Check
   */
  const windowSize = useWindowSize();
  const { width } = windowSize;
  const mobile = width < 700;

  const { patientid } = useParams();
  const {
    setPainLogRaw,
    painFuncArr,
    magnitudePainFuncArr,
  } = useMonthlyPainLogFilter();

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    fetchPatientStart({ token, patientid });
  }, []);

  useEffect(() => {
    if (painLogData && painLogData.length > 0) {
      setPainLogRaw(painLogData);
    }
  }, [loading]);

  const painTotalsConfig = {
    chartTitle: 'Pain Totals',
    linearGradient: [
      { id: 'painScore', stopColor: '#8884d8' },
      { id: 'functionScore', stopColor: '#82ca9d' },
      { id: 'total', stopColor: '#b0b30b' },
    ],
    area: [
      { dataKey: 'painScore', stroke: '#8884d8', fill: 'url(#painScore )' },
      {
        dataKey: 'functionScore',
        stroke: '#82ca9d',
        fill: 'url(#functionScore)',
      },
      { dataKey: 'total', stroke: '#b0b30b', fill: 'url(#total)' },
    ],
  };

  const magnitudeConfig = {
    chartTitle: 'Change in Magnitude',
    linearGradient: [
      { id: 'painScoreDelta', stopColor: '#8884d8' },
      { id: 'functionScoreDelta', stopColor: '#82ca9d' },
      { id: 'totalDelta', stopColor: '#b0b30b' },
    ],
    area: [
      {
        dataKey: 'painScoreDelta',
        stroke: '#8884d8',
        fill: 'url(#painScoreDelta )',
      },
      {
        dataKey: 'functionScoreDelta',
        stroke: '#82ca9d',
        fill: 'url(#functionScoreDelta)',
      },
      { dataKey: 'totalDelta', stroke: '#b0b30b', fill: 'url(#totalDelta)' },
    ],
  };

  const checkMobileRender = () => {
    if (mobile) {
      return (
        <>
          <Text bold textAlignment="center">
            Please Rotate Device to view charts
          </Text>
          <Image
            src="https://media.giphy.com/media/hScapv64LYdZe2YO26/giphy.gif"
            aspectRatio={1}
          />
        </>
      );
    }
    return (
      <>
        <Chart data={painFuncArr} chartConfig={painTotalsConfig} />
        <Chart data={magnitudePainFuncArr} chartConfig={magnitudeConfig} />
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <main>
            <ProfileCard patientInfo={patientInfo} />
            {painFuncArr.length > 0 ? (
              checkMobileRender()
            ) : (
              <Text>No Logged Data Found </Text>
            )}
          </main>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPatientStart: (accessData) => {
      dispatch(fetchPatientStart(accessData));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.patientReducer.loading,
    patientInfo: state.patientReducer.viewingPatientData,
    painLogData: state.patientReducer.viewingPatientLogs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfilePage);
