import * as React from 'react';
import Theme from '../../theme.styles';

/**
 * Styles
 */
import { AvatarContainer, AvatarText } from './Avatar.styles';

/**
 * Components
 */
import { Image } from '../Image';
/**
 * Variants
 */
const colours = {
  black: 'avatar--black',
  white: 'avatar--white',
  red: 'avatar--red',
};

/**
 * An Avatar component
 */
const Avatar = ({
  alt,
  children,
  small,
  colour = 'black',
  image,
  title = children,
  onClick,
  handleMouseEnter,
}) => {
  const getInitialsFromName = (name) =>
    name
      .split(' ')
      .map((part) => part.substr(0, 1))
      .join('')
      .substr(0, 2)
      .toUpperCase();
  const initials = children && getInitialsFromName(children);

  return (
    <Theme>
      <AvatarContainer
        colours={colours[colour]}
        title={title}
        onClick={onClick}
        small={small}
        onMouseEnter={handleMouseEnter}
      >
        {initials ? (
          <AvatarText className="avatar__text" small={small}>
            {initials}
          </AvatarText>
        ) : (
          <Image isRound aspectRatio={1 / 1} src={image} alt={alt} />
        )}
      </AvatarContainer>
    </Theme>
  );
};

export default Avatar;

//ratio={16 / 9}
