import React from 'react';

/**
 * Styles
 */

import {
  HeaderContainer,
  Image,
  ImageContainer,
  HeaderTitle,
} from './HeaderOnly.styles';
/**
 * HeaderOnly
 */
const HeaderOnly = () => (
  <HeaderContainer>
    <ImageContainer>
      <Image src={require('../../assets/images/Asset 7.png')} />
    </ImageContainer>
    <HeaderTitle>Pet2Vet</HeaderTitle>
  </HeaderContainer>
);

export default HeaderOnly;
