import styled from 'styled-components';

export const NewPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: auto;
`;

export const NewPatientTitle = styled.h2`
  margin: 10px 0;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;
