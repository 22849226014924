import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Actions
 */

import { emailSignInStart } from '../../redux/user/user.actions';

/**
 * Components
 */
import { FormInput } from '../Form-Input';
import { Button } from '../Button';

/**
 * Styles
 */
import {
  EditUserContainer,
  EditUserHeader,
  EditUserTitle,
  FormContainer,
  ButtonsBarContainer,
} from './EditUser.styles';

/**
 * SignUp Component
 */

const EditUser = ({ emailSignInStart, loading, currentUser }) => {
  const history = useHistory();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      email: currentUser.email,
      password: currentUser.password,
      name: currentUser.name,
      telephone: currentUser.telephone,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Required'),
      password: Yup.string()
        .min(8, 'Password should be longer than 8 characters')
        .required(),
      name: Yup.string()
        .min(2, 'Password should be longer than 8 characters')
        .required(),
      telephone: Yup.string()
        .min(10, 'Telephone number should be longer')
        .max(11, 'Telephone number should be shorter')
        .required(),
    }),
    onSubmit: (values) => {
      // emailSignInStart(values);
    },
  });

  console.log(values);

  return (
    <EditUserContainer>
      <EditUserHeader>
        <EditUserTitle>Edit user account</EditUserTitle>
        <span>Edit below</span>
      </EditUserHeader>
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FormInput
            name="email"
            type="email"
            id="email"
            label="Email"
            value={values.email}
            handleChange={handleChange}
            required
          />
          {touched.email && errors.email && <div>{errors.email}</div>}
          <FormInput
            name="name"
            type="name"
            id="name"
            label="Name"
            value={values.name}
            handleChange={handleChange}
            required
          />
          {touched.name && errors.name && <div>{errors.name}</div>}
          <FormInput
            name="telephone"
            type="telephone"
            id="telephone"
            label="Telephone"
            value={values.telephone}
            handleChange={handleChange}
            required
          />
          {touched.telephone && errors.telephone && (
            <div>{errors.telephone}</div>
          )}
        </FormContainer>
        <ButtonsBarContainer>
          <Button loading={loading} type="submit">
            {' '}
            Update{' '}
          </Button>
        </ButtonsBarContainer>
      </form>
    </EditUserContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    emailSignInStart: (emailAndPassword) => {
      dispatch(emailSignInStart(emailAndPassword));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
    currentUser: state.userReducer.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
