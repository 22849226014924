import * as React from 'react';

/**
 * Styles
 */
import Theme from '../../theme.styles';
import { PRContainer } from './PatientRecords.styles';

/**
 * Components
 */

import { PatientRecordsHeader, PatientRecordsBody } from '../PatientRecords';

/**
 * A drawer container that slides out and overlays the main body
 */
const PatientRecords = ({ className, patientRecordsData }) => {
  return (
    <Theme>
      <PRContainer>
        <PatientRecordsHeader />
        <PatientRecordsBody patientRecordsData={patientRecordsData} />
      </PRContainer>
    </Theme>
  );
};

export default PatientRecords;
