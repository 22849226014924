import styled, { css } from 'styled-components';
import { spacing } from '../../config/styles/spacing';
import { zIndex } from '../../config/styles/z-index';

const size = '3.5rem';

const iconStyles = css`
  fill: ${(props) => props.theme.colours.uiOne};

  &:hover {
    fill: ${(props) => props.theme.colours.uiTwo};
  }
`;

const invertedIconStyles = css`
  fill: ${(props) => props.theme.colours.uiTwo};

  &:hover {
    fill: ${(props) => props.theme.colours.uiOne};
  }
`;

const tertiaryIconStyles = css`
  fill: ${(props) => props.theme.colours.uiOne};

  &:hover {
    fill: ${(props) => props.theme.colours.brandTwo};
  }
`;

const getIconStyles = ({ icn }) => {
  switch (icn.variant) {
    default:
      return iconStyles;
    case 'inverted':
      return invertedIconStyles;
    case 'tertiary':
      return tertiaryIconStyles;
  }
};

const smallIconSize = css`
  width: calc(${size} * 0.35);
  height: calc(${size}* 0.35);
`;

const mediumIconSize = css`
  width: calc(${size} * 0.5);
  height: calc(${size}* 0.5);
`;

const largeIconSize = css`
  width: calc(${size} * 1.25);
  height: calc(${size}* 1.25);
`;

const getIconSize = ({ icn }) => {
  switch (icn.size) {
    default:
      return mediumIconSize;
    case 'small':
      return smallIconSize;
    case 'medium':
      return mediumIconSize;
    case 'large':
      return largeIconSize;
  }
};

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-right: ${spacing.md};
  vertical-align: middle;
  ${getIconSize}

  @media screen and (max-width: 800px) {
    padding: ${spacing.xxs};
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: -1;
  /* -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
`;

export const IconButton = styled.button`
  display: inline-flex;
  width: auto;
  line-height: 3.125rem;
  padding: 0;
  margin: ${(props) => (props.margin ? `${spacing.sm}` : 0)};
  font-size: ${(props) => props.theme.fontSizes.small};
  cursor: pointer;
  vertical-align: middle;
  border: none;
  background-color: transparent;

  ${getIconStyles};
`;
