import React from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Components
 */

import { Button } from '../../components/Button';

/**
 * Styles
 */
import { DropdownMenuContainer } from './DropdownMenu.styles';

/**
 * DropDownMenu Component
 */

const DropdownMenu = ({ children, handleSignOut, handleMouseLeave }) => {
  let history = useHistory();

  return (
    <DropdownMenuContainer onMouseLeave={handleMouseLeave}>
      {children}
      <Button
        handleClick={() => history.push('/webuser/myaccount')}
        fullWidthButton
        variant="transparent"
      >
        My Account
      </Button>
      <Button handleClick={handleSignOut} fullWidthButton variant="transparent">
        Sign out
      </Button>
    </DropdownMenuContainer>
  );
};

export default DropdownMenu;
